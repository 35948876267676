import { TOutputProduct } from '@/@types/outputs';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { TableActions } from '@/components/common/Table/TableActions';
import { convertNumberToMoney } from '@/utils';

export const OutputProductsTableColumns: ColumnDefinitionType<TOutputProduct>[] =
  [
    {
      key: 'id',
      header: 'Item',
      sort: '',
    },
    {
      key: 'outputProduct',
      header: 'Produto',
      sort: '',
      render: (outputProduct) => outputProduct.outputProduct?.name || '',
    },
    {
      key: 'outputUnit',
      header: 'Unidade',
      sort: '',
      render: (outputProduct) => outputProduct.outputUnit?.abbreviation || '',
    },
    {
      key: 'productQuantity',
      header: 'Quantidade',
      sort: '',
      render: (outputProduct) => outputProduct.productQuantity,
    },
    {
      key: 'priceGross',
      header: 'Preço Bruto',
      sort: '',
      render: (outputProduct) => convertNumberToMoney(outputProduct.priceGross),
    },
    {
      key: 'discount',
      header: 'Desconto',
      sort: '',
      render: (outputProduct) => convertNumberToMoney(outputProduct.discount),
    },
    {
      key: 'subtotalProduct',
      header: 'Subtotal Produto',
      sort: '',
      render: (outputProduct) =>
        convertNumberToMoney(outputProduct.subtotalProduct),
    },
    {
      key: 'unitaryValue',
      header: 'Valor Unitário',
      sort: '',
      render: (outputProduct) =>
        convertNumberToMoney(outputProduct.unitaryValue),
    },
    {
      key: 'id',
      header: 'Ações',
      sort: '',
      render: (outputProduct) => (
        <TableActions
          id={outputProduct.productId}
          editButtonPermission="sales_create"
          deleteButtonPermission="sales_create"
          restoreButtonPermission="sales_restore"
          shouldDisableActions={outputProduct.isOutputFinished}
          onEdit={() => outputProduct.handleEditProduct(outputProduct)}
          onDelete={() =>
            outputProduct.handleRemoveSelectedProduct(outputProduct.productId)
          }
        />
      ),
    },
  ];
