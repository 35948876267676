import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import * as zod from 'zod';
import { MoneyInput } from '@/components/common';
import { Product } from '@/@types/product';
import { Unit } from '@/@types/unit';
import { Category } from '@/@types/category';
import { Settings } from '@/@types/settings';

const productFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome do produto')
    .transform((name) => name.trim().toUpperCase()),
  buyPrice: zod.coerce.number().min(0, 'Preço de compra inválido'),
  salePrice: zod.coerce.number().min(0, 'Preço de venda inválido'),
  unitId: zod.string().min(1, 'Informe a unidade do produto'),
  categoryId: zod.string().min(1, 'Informe a categoria do produto'),
});

export type ProductFormData = zod.infer<typeof productFormSchema>;

interface IProductFormProps {
  product?: Product;
  units: Unit[];
  categories: Category[];
  isLoading: boolean;
  settings?: Settings;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: ProductFormData) => void;
  onCancel: () => void;
}

export function ProductForm({
  product,
  units,
  categories,
  isLoading,
  settings,
  onSubmit,
  onCancel,
}: IProductFormProps) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProductFormData>({
    resolver: zodResolver(productFormSchema),
    defaultValues: {
      name: product?.name || '',
      buyPrice: product?.buyPrice || 0,
      salePrice: product?.salePrice || 0,
      unitId: product ? product?.unitId : settings?.productUnitId || '',
      categoryId: product
        ? product?.categoryId
        : settings?.productCategoryId || '',
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Nome"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={isLoading}
            {...register('name')}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Controller
              name="buyPrice"
              control={control}
              render={({ field }) => (
                <MoneyInput
                  {...field}
                  type="text"
                  label="Preço de Compra"
                  fullWidth
                  onChange={({ target }) =>
                    setValue('buyPrice', Number(target.value))
                  }
                  error={!!errors.buyPrice}
                  helperText={errors.buyPrice?.message}
                  disabled={isLoading}
                />
              )}
            />
            <Controller
              name="salePrice"
              control={control}
              render={({ field }) => (
                <MoneyInput
                  {...field}
                  type="text"
                  label="Preço de Venda"
                  fullWidth
                  onChange={({ target }) =>
                    setValue('salePrice', Number(target.value))
                  }
                  error={!!errors.salePrice}
                  helperText={errors.salePrice?.message}
                  disabled={isLoading}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl fullWidth error={!!errors.unitId}>
              <Controller
                name="unitId"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={units}
                    getOptionLabel={(option) => option.abbreviation}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    value={
                      value ? units?.find((unit) => unit.id === value) : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Unidade"
                        error={!!errors.unitId}
                      />
                    )}
                  />
                )}
              />
              {!!errors.unitId && (
                <FormHelperText>{errors.unitId.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={!!errors.categoryId}>
              <Controller
                name="categoryId"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    value={
                      value
                        ? categories?.find((category) => category.id === value)
                        : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Categoria"
                        error={!!errors.categoryId}
                      />
                    )}
                  />
                )}
              />
              {!!errors.categoryId && (
                <FormHelperText>{errors.categoryId.message}</FormHelperText>
              )}
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
