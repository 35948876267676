import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export function ErrorList({
  text = 'Falha ao obter os dados.',
}: {
  text?: string;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Error fontSize="inherit" color="error" sx={{ fontSize: '4rem' }} />
      </Box>
      <Typography variant="body1" color="gray">
        {text}
      </Typography>
    </Box>
  );
}
