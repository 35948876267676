import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface ICustomersTableActionsProps {
  id: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditCustomer: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteCustomer: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestoreCustomer: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditCustomerMouseEnter: (id: string) => void;
}

export function CustomersTableActions({
  id,
  deletedAt,
  onEditCustomer,
  onDeleteCustomer,
  onRestoreCustomer,
  onEditCustomerMouseEnter,
}: ICustomersTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={['customers_update']}>
        <Tooltip title="Alterar">
          <IconButton
            onMouseEnter={() => onEditCustomerMouseEnter(id)}
            onClick={() => onEditCustomer(id)}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Can>
      {deletedAt ? (
        <Can permissions={['customers_restore']}>
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => onRestoreCustomer(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RestoreFromTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={['customers_delete']}>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => onDeleteCustomer(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
