import { Product } from '@/@types/product';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { ProductsTableActions } from './ProductsTableActions';

export const ProductsTableColumns: ColumnDefinitionType<Product>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
  },
  {
    key: 'quantity',
    header: 'Quantidade',
    sort: 'quantity',
  },
  {
    key: 'unit',
    header: 'Unidade',
    render: (product) => product.unit?.abbreviation || '',
    sort: 'unit.abbreviation',
  },
  {
    key: 'category',
    header: 'Categoria',
    render: (product) => product.category?.name || '',
    sort: 'category.name',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: ProductsTableActions,
  },
];
