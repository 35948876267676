import { Vendor } from '@/@types/vendor';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { VendorForm, VendorFormData } from '../VendorForm';
import { VendorFormPlaceholder } from '../VendorForm/VendorFormPlaceholder';

interface IVendorModalProps {
  vendor?: Vendor;
  isOpen: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: VendorFormData) => void;
}

export function VendorModal({
  vendor,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isSubmitting,
}: IVendorModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      closeOnBackdropClick={false}
    >
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {vendor ? 'Alterar Fornecedor' : 'Novo Fornecedor'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting ? <LinearProgress /> : null}
      {isLoading ? (
        <VendorFormPlaceholder />
      ) : (
        <VendorForm
          vendor={vendor}
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
