import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { Staff } from '@/@types/staff';
import { StaffsTable } from '../StaffsTable';
import { StaffsTableColumns } from '../StaffsTable/StaffsTableColumns';

type TStaffsData = {
  staffs: Staff[];
  totalCount: number;
};

interface IStaffsListProps {
  isLoading: boolean;
  isError: boolean;
  data: TStaffsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof Staff;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function StaffsList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IStaffsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder headers={['Nome', 'Criado em', 'Ações']} />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados dos colaboradores." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <StaffsTable
        columns={StaffsTableColumns}
        data={data?.staffs || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
