import { getCepFromViaCepApi } from '@/services/getCepFromViaCepApi';
import { useQuery } from '@tanstack/react-query';

interface UseViaCepAPI {
  postalCode?: string;
  enabled: boolean;
}

export function useViaCepAPI({ postalCode, enabled }: UseViaCepAPI) {
  return useQuery({
    queryKey: ['postalCode', { postalCode }],
    queryFn: () => getCepFromViaCepApi(postalCode as string),
    enabled,
    staleTime: 1000 * 60 * 1, // 1 minute
  });
}
