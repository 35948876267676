import { TEntryProduct } from '@/@types/entries';
import { Table } from '@/components/common';
import { EntryProductsTableColumns } from './EntryProductsTableColumns';

interface IEntryProductsListProps {
  products: TEntryProduct[];
  actions: object;
}

export function EntryProductsList({
  products,
  actions,
}: IEntryProductsListProps) {
  return (
    <Table
      data={products}
      columns={EntryProductsTableColumns}
      additionalData={actions}
      maxHeight={255}
    />
  );
}
