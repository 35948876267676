import { Container } from '@mui/material';
import React, { ReactNode } from 'react';

interface IAuthContainerProps {
  children: ReactNode;
}

export function AuthContainer({ children }: IAuthContainerProps) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      {children}
    </Container>
  );
}
