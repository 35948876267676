/* eslint-disable no-nested-ternary */
import { Chip } from '@mui/material';

interface IEntryStatusLabelProps {
  isFinished: boolean;
  deletedAt?: string;
}

export function EntryStatusLabel({
  isFinished,
  deletedAt,
}: IEntryStatusLabelProps) {
  return (
    <Chip
      color={deletedAt ? 'default' : isFinished ? 'primary' : 'warning'}
      label={deletedAt ? 'Excluído' : isFinished ? 'Finalizado' : 'Pendente'}
    />
  );
}
