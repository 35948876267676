import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[800],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
