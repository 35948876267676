import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface IUsersTableActionsProps {
  id: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditUser: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteUser: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestoreUser: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditUserMouseEnter: (id: string) => void;
}

export function UsersTableActions({
  id,
  deletedAt,
  onEditUser,
  onDeleteUser,
  onRestoreUser,
  onEditUserMouseEnter,
}: IUsersTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={['users_update']}>
        <Tooltip title="Alterar">
          <IconButton
            onMouseEnter={() => onEditUserMouseEnter(id)}
            onClick={() => onEditUser(id)}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Can>
      {deletedAt ? (
        <Can permissions={['users_restore']}>
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => onRestoreUser(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RestoreFromTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={['users_delete']}>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => onDeleteUser(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
