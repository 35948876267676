import { TOutputInstallment } from '@/@types/outputs';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { TableActions } from '@/components/common/Table/TableActions';
import { convertNumberToMoney } from '@/utils';
import { format } from 'date-fns';

export const OutputInstallmentsTableColumns: ColumnDefinitionType<TOutputInstallment>[] =
  [
    {
      key: 'id',
      header: 'Parcela',
      sort: '',
    },
    {
      key: 'documentType',
      header: 'Tipo de Documento',
      sort: '',
      render: (outputInstallment) => outputInstallment.documentType?.name || '',
    },
    {
      key: 'dueDate',
      header: 'Data de Vencimento',
      sort: '',
      render: (outputInstallment) =>
        format(outputInstallment?.dueDate, 'dd/MM/yyyy'),
    },
    {
      key: 'amount',
      header: 'Valor',
      sort: '',
      render: (outputInstallment) =>
        convertNumberToMoney(outputInstallment.amount),
    },
    {
      key: 'id',
      header: 'Ações',
      sort: '',
      render: (outputInstallment) => (
        <TableActions
          id={outputInstallment.id}
          editButtonPermission="sales_create"
          deleteButtonPermission="sales_create"
          restoreButtonPermission="sales_restore"
          shouldDisableActions={outputInstallment.isOutputFinished}
          onEdit={() =>
            outputInstallment.handleEditInstallment(outputInstallment)
          }
          onDelete={() =>
            outputInstallment.handleRemoveSelectedInstallment(
              outputInstallment.id
            )
          }
        />
      ),
    },
  ];
