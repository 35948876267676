import React, { useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SidebarDrawerContext } from '@/contexts/SidebarDrawerContext';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { AuthContext } from '@/contexts/AuthContext';
import { getFirstAndLastName, stringAvatar } from '@/utils';
import { getAvailableUserRoutes } from '@/modules/core/auth/utils/getUserAvailableRoutes';

import { SidebarIcons } from './SidebarIcons';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down('lg')]: {
    width: 0,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export function Sidebar() {
  const theme = useTheme();
  const { isDrawerOpen, closeDrawer } = useContext(SidebarDrawerContext);
  const { user } = useContext(AuthContext);

  const router = useRouter();

  const userMenuRoutes = getAvailableUserRoutes({
    isAdmin: !!user?.is_admin,
    permissions: user?.role?.permissions,
  });

  const handleDrawerClose = () => {
    closeDrawer();
  };

  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <DrawerHeader>
        {user ? (
          <Box display="flex" alignItems="center">
            <Avatar {...stringAvatar(user.name)} />
            <Box ml={1}>
              <Typography component="p" variant="body2">
                {getFirstAndLastName(user.name)}
              </Typography>
              <Typography component="p" variant="caption" color="gray">
                {user.is_admin ? 'Admin' : user.role.name}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <IconButton onClick={handleDrawerClose} sx={{ marginLeft: 'auto' }}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {userMenuRoutes.map((menuRoute) => (
          <React.Fragment key={menuRoute.name}>
            {menuRoute.name === 'Configurações' ? <Divider /> : null}
            <Link href={menuRoute.path}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <Tooltip
                  title={menuRoute.name}
                  placement="right"
                  disableHoverListener={isDrawerOpen}
                  disableFocusListener={isDrawerOpen}
                  disableInteractive={isDrawerOpen}
                  disableTouchListener={isDrawerOpen}
                >
                  <ListItemButton
                    selected={router.asPath.includes(menuRoute.path)}
                    sx={{
                      minHeight: 48,
                      justifyContent: isDrawerOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isDrawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SidebarIcons sidebarName={menuRoute.name} />
                    </ListItemIcon>
                    <ListItemText
                      primary={menuRoute.name}
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </Link>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}
