/* eslint-disable no-nested-ternary */
import { Stack, Typography } from '@mui/material';
import { grey, blue, orange } from '@mui/material/colors';
import { useMemo } from 'react';

interface IEntryStatusLabelBulletProps {
  isFinished: boolean;
  deletedAt?: string;
}

export function EntryStatusLabelBullet({
  isFinished,
  deletedAt,
}: IEntryStatusLabelBulletProps) {
  const color = useMemo(() => {
    return deletedAt ? grey[700] : isFinished ? blue[700] : orange[700];
  }, [deletedAt, isFinished]);

  const label = useMemo(() => {
    return deletedAt ? 'Excluído' : isFinished ? 'Finalizado' : 'Pendente';
  }, [deletedAt, isFinished]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        '&::before': {
          content: '""',
          display: 'inline-block',
          width: '0.5rem',
          height: '0.5rem',
          borderRadius: '50%',
          marginRight: '0.5rem',
          backgroundColor: color,
        },
      }}
    >
      <Typography color={color}>{label}</Typography>
    </Stack>
  );
}
