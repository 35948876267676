import { ICreatePriceTableProduct } from '@/@types/price-table';

export const transformPriceTableProductsToBackend = (
  products: ICreatePriceTableProduct[]
) => {
  return products.map((product) => ({
    product_id: product.productId,
    product_unit_id: product.productUnitId,
    product_price: product.productPrice,
  }));
};
