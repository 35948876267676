/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { PriceTable } from '@/@types/price-table';

export function usePriceTablesFilters() {
  const router = useRouter();

  const {
    search = '',
    sort = 'desc',
    sort_by = 'created_at',
    deleted,
    page = 1,
    per_page = 10,
  } = router.query;

  const [tempSearch, setTempSearch] = useState(search || '');
  const [showDeleted, setShowDeleted] = useState(deleted === 'true');

  const handleFilter = () => {
    router.replace({
      query: {
        page: 1,
        search: tempSearch,
        sort,
        sort_by,
        deleted: showDeleted,
        per_page,
      },
    });
  };

  const handleClearFilters = () => {
    router.replace('/price_tables');

    setTempSearch('');
    setShowDeleted(false);
  };

  const handlePageChange = (newPage: number) => {
    router.replace({
      query: {
        ...router.query,
        page: newPage + 1,
      },
    });
  };

  const handlePerPageChange = (newPerPage: number) => {
    router.replace({
      query: {
        ...router.query,
        per_page: newPerPage,
      },
    });
  };

  const handleSort = (column: string) => {
    router.replace({
      query: {
        ...router.query,
        sort: sort === 'asc' ? 'desc' : 'asc',
        sort_by: column,
      },
    });
  };

  const handleShowDeletedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowDeleted(event.target.checked);
  };

  return {
    search: search as string,
    sort: sort as 'asc' | 'desc',
    sortBy: sort_by as keyof PriceTable,
    deleted: deleted === 'true',
    page: Number(page),
    perPage: Number(per_page),
    handleFilter,
    handleClearFilters,
    handlePageChange,
    handlePerPageChange,
    handleSort,
    tempSearch,
    setTempSearch,
    showDeleted,
    setShowDeleted,
    handleShowDeletedChange,
  };
}
