import { Customer } from '@/@types/customer';
import { Operation } from '@/@types/operation';
import { PaymentTerm } from '@/@types/payment-term';
import { Staff } from '@/@types/staff';
import { Vendor } from '@/@types/vendor';
import { formatDateTime } from '@/utils';

interface PrintInfoProps {
  number?: string | number;
  date?: string;
  operation?: Operation;
  person?: Customer | Vendor;
  personLabel?: string;
  staff?: Staff;
  staffLabel?: string;
  paymentTerm?: PaymentTerm | null;
  note?: string;
}

export function PrintInfo({
  number,
  date,
  operation,
  person,
  personLabel = 'Cliente........:',
  staff,
  staffLabel = 'Vendedor.....:',
  paymentTerm,
  note,
}: PrintInfoProps) {
  return (
    <>
      <table cellSpacing="0" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>Número: {number}</td>
            <td style={{ textAlign: 'right' }}>
              Data: {formatDateTime(date as string)}
            </td>
          </tr>
        </tbody>
      </table>
      <ul style={{ listStyle: 'none' }}>
        <li>
          Tipo............: {operation?.number} - {operation?.name}
        </li>
        <li>
          {personLabel} {person?.number} - {person?.name}
        </li>
        <li>Endereço....: {person?.streetName || '-'}</li>
        <li>
          {person?.streetNumber
            ? `Nº ${person?.streetNumber?.padStart(4, '0')}`
            : ''}{' '}
          {person?.neighborhood}
        </li>
        <li>{person?.complement}</li>
        <li>{person?.landmark}</li>
        <li>
          {staffLabel} {staff?.number} - {staff?.name}
        </li>
        {paymentTerm ? (
          <li>
            Cond. Pagto.: {paymentTerm?.number} - {paymentTerm?.name}
          </li>
        ) : null}
        {note ? <li>Observacoes: {note}</li> : null}
      </ul>
    </>
  );
}
