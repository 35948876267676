/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as zod from 'zod';
import { useSnackbar } from 'notistack';
import { useViaCepAPI } from '@/modules/core/hooks';
import { useEffect } from 'react';
import { Place } from '@mui/icons-material';
import { MaskedInput } from '@/components/common';
import {
  CELLPHONE_MASK,
  CEP_MASK,
  CNPJ_MASK,
  CPF_MASK,
  PHONE_MASK,
  UNIDENTIFIED_PHONE_MASK,
} from '@/components/common/MaskedInput/MaskedInput.utils';
import { Vendor } from '@/@types/vendor';

const vendorFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe a razão social/nome do fornecedor')
    .transform((name) => name.trim().toUpperCase()),
  type: zod.string().min(2, 'Informe o tipo do fornecedor'),
  businessName: zod
    .string()
    .min(2, 'Informe o apelido/nome fantasia')
    .transform((businessName) => businessName.trim().toUpperCase()),
  employerNumber: zod.string().optional(),
  nationalIdentity: zod.string().optional(),
  postalCode: zod.string().optional(),
  streetName: zod
    .string()
    .optional()
    .transform((streetName) => streetName?.trim().toUpperCase()),
  streetNumber: zod.string().optional(),
  complement: zod
    .string()
    .optional()
    .transform((complement) => complement?.trim().toUpperCase()),
  landmark: zod
    .string()
    .optional()
    .transform((landmark) => landmark?.trim().toUpperCase()),
  neighborhood: zod
    .string()
    .optional()
    .transform((neighborhood) => neighborhood?.trim().toUpperCase()),
  city: zod
    .string()
    .optional()
    .transform((city) => city?.trim().toUpperCase()),
  phone: zod.string().optional(),
  email: zod.string().optional(),
  note: zod.string().optional(),
});

export type VendorFormData = zod.infer<typeof vendorFormSchema>;

interface IVendorFormProps {
  vendor?: Vendor;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: VendorFormData) => void;
  onCancel: () => void;
}

const VENDOR_TYPES = [
  { id: 'PF', label: 'Pessoa Física' },
  { id: 'PJ', label: 'Pessoa Jurídica' },
];

export function VendorForm({
  vendor,
  isLoading,
  onSubmit,
  onCancel,
}: IVendorFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    getValues,
    setValue,
  } = useForm<VendorFormData>({
    resolver: zodResolver(vendorFormSchema),
    defaultValues: {
      name: vendor?.name || '',
      type: vendor?.type || 'PJ',
      businessName: vendor?.businessName || '',
      employerNumber: vendor?.employerNumber || '',
      nationalIdentity: vendor?.nationalIdentity || '',
      postalCode: vendor?.postalCode || '',
      streetName: vendor?.streetName || '',
      streetNumber: vendor?.streetNumber || '',
      complement: vendor?.complement || '',
      landmark: vendor?.landmark || '',
      neighborhood: vendor?.neighborhood || '',
      city: vendor?.city || '',
      phone: vendor?.phone || '',
      email: vendor?.email || '',
      note: vendor?.note || '',
    },
  });

  const handlePostalCodeError = (error?: boolean) => {
    const errorMessage = error
      ? 'CEP inválido'
      : 'Não foi possível buscar o cep';

    enqueueSnackbar(errorMessage, {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom',
      },
    });
  };

  const handleViaCEPSuccess = () => {
    const response = viaCepQuery.data as any;

    if (response.data.erro) {
      handlePostalCodeError(response.data.erro);
    }

    setValue('streetName', response.data.logradouro);
    setValue('complement', response.data.complemento);
    setValue('neighborhood', response.data.bairro);
    setValue('city', response.data.localidade);
  };

  const vendorType = watch('type');
  const phone = watch('phone');
  const postalCode = watch('postalCode');
  const streetName = watch('streetName');
  const complement = watch('complement');
  const neighborhood = watch('neighborhood');
  const city = watch('city');

  const viaCepQuery = useViaCepAPI({
    enabled:
      !!postalCode &&
      !postalCode?.includes('_') &&
      postalCode.length === 9 &&
      !!dirtyFields.postalCode,
    postalCode,
  });

  useEffect(() => {
    if (viaCepQuery.data) {
      handleViaCEPSuccess();
    }
  }, [viaCepQuery.data]);

  const handleNameBlur = (currentName: string) => {
    const currentBusinessName = getValues('businessName');

    if (currentBusinessName) return;

    setValue('businessName', currentName);
  };

  return (
    <>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Box component="form">
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <Typography variant="body1" fontWeight="bold">
              Dados do Fornecedor
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack spacing={3}>
            <FormControl error={!!errors.type}>
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={VENDOR_TYPES}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    value={
                      value
                        ? VENDOR_TYPES.find((type) => type.id === value)
                        : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Tipo"
                        error={!!errors.type}
                      />
                    )}
                  />
                )}
              />
              {!!errors.type && (
                <FormHelperText>{errors.type.message}</FormHelperText>
              )}
            </FormControl>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <TextField
                type="text"
                label={vendorType === 'PF' ? 'Nome' : 'Razão Social'}
                fullWidth
                autoFocus
                error={!!errors.name}
                helperText={errors.name?.message}
                disabled={isLoading}
                {...register('name', {
                  onBlur: ({ target }) => handleNameBlur(target.value),
                })}
              />
              <TextField
                type="text"
                label={vendorType === 'PF' ? 'Apelido' : 'Nome Fantasia'}
                fullWidth
                error={!!errors.businessName}
                helperText={errors.businessName?.message}
                disabled={isLoading}
                {...register('businessName')}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <MaskedInput
                mask={vendorType === 'PF' ? CPF_MASK : CNPJ_MASK}
                disabled={isLoading}
                textFieldProps={{
                  type: 'text',
                  label: vendorType === 'PF' ? 'CPF' : 'CNPJ',
                  fullWidth: true,
                  error: !!errors.employerNumber,
                  helperText: errors.employerNumber?.message,
                  ...register('employerNumber'),
                }}
                {...register('employerNumber')}
              />
              <TextField
                type="text"
                label={vendorType === 'PF' ? 'RG' : 'Inscrição Estadual'}
                fullWidth
                error={!!errors.nationalIdentity}
                helperText={errors.nationalIdentity?.message}
                disabled={isLoading}
                {...register('nationalIdentity')}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <MaskedInput
                mask={
                  phone?.includes('____-____')
                    ? UNIDENTIFIED_PHONE_MASK
                    : (vendor?.id && phone?.length === 11) ||
                      phone?.charAt(5) === '9'
                    ? CELLPHONE_MASK
                    : PHONE_MASK
                }
                disabled={isLoading}
                textFieldProps={{
                  type: 'text',
                  label: 'Telefone',
                  fullWidth: true,
                  error: !!errors.phone,
                  helperText: errors.phone?.message,
                  ...register('phone'),
                }}
                {...register('phone')}
              />
              <TextField
                type="email"
                label="E-mail"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
                disabled={isLoading}
                {...register('email')}
              />
            </Stack>
            <TextField
              label="Observações"
              multiline
              maxRows={4}
              {...register('note')}
            />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" my={3}>
            <Typography variant="body1" fontWeight="bold">
              Endereço
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <MaskedInput
              mask={CEP_MASK}
              disabled={isLoading}
              textFieldProps={{
                type: 'text',
                label: 'CEP',
                error: !!errors.postalCode,
                helperText: errors.postalCode?.message,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {viaCepQuery.isLoading &&
                      viaCepQuery.fetchStatus !== 'idle' ? (
                        <CircularProgress size={16} />
                      ) : (
                        <Place />
                      )}
                    </InputAdornment>
                  ),
                },
                ...register('postalCode'),
              }}
              {...register('postalCode')}
            />
            <TextField
              type="text"
              label="Endereço"
              fullWidth
              error={!!errors.streetName}
              helperText={errors.streetName?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!streetName }}
              {...register('streetName')}
            />
            <TextField
              type="text"
              label="Número"
              error={!!errors.streetNumber}
              helperText={errors.streetNumber?.message}
              disabled={isLoading}
              {...register('streetNumber')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} my={3}>
            <TextField
              type="text"
              label="Complemento"
              fullWidth
              error={!!errors.complement}
              helperText={errors.complement?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!complement }}
              {...register('complement')}
            />
            <TextField
              type="text"
              label="Ponto de Referência"
              fullWidth
              error={!!errors.landmark}
              helperText={errors.landmark?.message}
              disabled={isLoading}
              {...register('landmark')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} my={3}>
            <TextField
              type="text"
              label="Bairro"
              fullWidth
              error={!!errors.neighborhood}
              helperText={errors.neighborhood?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!neighborhood }}
              {...register('neighborhood')}
            />
            <TextField
              type="text"
              label="Cidade"
              fullWidth
              error={!!errors.city}
              helperText={errors.city?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!city }}
              {...register('city')}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
