import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface IStaffsTableActionsProps {
  id: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditStaff: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteStaff: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestoreStaff: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditStaffMouseEnter: (id: string) => void;
}

export function StaffsTableActions({
  id,
  deletedAt,
  onEditStaff,
  onDeleteStaff,
  onRestoreStaff,
  onEditStaffMouseEnter,
}: IStaffsTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={['staffs_update']}>
        <Tooltip title="Alterar">
          <IconButton
            onMouseEnter={() => onEditStaffMouseEnter(id)}
            onClick={() => onEditStaff(id)}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Can>
      {deletedAt ? (
        <Can permissions={['staffs_restore']}>
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => onRestoreStaff(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RestoreFromTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={['staffs_delete']}>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => onDeleteStaff(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
