import { Product } from '@/@types/product';
import { Table } from '@/components/common';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';

export interface IProductsTableProps {
  data: Product[];
  columns: ColumnDefinitionType<Product>[];
  sort?: 'desc' | 'asc';
  sortBy?: keyof Product;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function ProductsTable({
  data,
  columns,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IProductsTableProps) {
  return (
    <Table
      data={data}
      columns={columns}
      additionalData={actions}
      sort={sort}
      sortBy={sortBy}
      onSort={onSort}
      pagination={pagination}
    />
  );
}
