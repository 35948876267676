import { TOutputProduct } from '@/@types/outputs';
import { convertNumberToMoney } from '@/utils';
import { Stack, Typography, useTheme } from '@mui/material';

type OutputTotalProps = {
  products: TOutputProduct[];
};

export function OutputTotal({ products }: OutputTotalProps) {
  const theme = useTheme();

  const totalGross = products.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.priceGross * product.productQuantity);
  }, 0);

  const totalDiscount = products.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.discount);
  }, 0);

  const total = totalGross - totalDiscount;

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 3 }}
      justifyContent="center"
      p={1}
      borderRadius={1}
      border={1}
      borderColor={theme.palette.grey[300]}
      sx={{ backgroundColor: theme.palette.grey[100] }}
    >
      <Stack
        direction="row"
        spacing={{ xs: 0, sm: 0.5 }}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
      >
        <Typography
          component="span"
          width={{ xs: '40%', sm: 'auto' }}
          textAlign="right"
        >
          Total Bruto:
        </Typography>
        <Typography
          component="span"
          fontWeight="bold"
          width={{ xs: '40%', sm: 'auto' }}
        >
          {convertNumberToMoney(totalGross)}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={{ xs: 0, sm: 0.5 }}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
      >
        <Typography
          component="span"
          width={{ xs: '40%', sm: 'auto' }}
          textAlign="right"
        >
          Desconto:
        </Typography>
        <Typography
          component="span"
          fontWeight="bold"
          width={{ xs: '40%', sm: 'auto' }}
        >
          {convertNumberToMoney(totalDiscount)}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={{ xs: 0, sm: 0.5 }}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
      >
        <Typography
          component="span"
          width={{ xs: '40%', sm: 'auto' }}
          textAlign="right"
        >
          Total:
        </Typography>
        <Typography
          component="span"
          fontWeight="bold"
          width={{ xs: '40%', sm: 'auto' }}
        >
          {convertNumberToMoney(total)}
        </Typography>
      </Stack>
    </Stack>
  );
}
