import { ElementType, ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { Can } from '../Can';

interface IListHeaderProps {
  title: string;
  subtitle?: string;
  buttonTitle?: string;
  buttonIcon?: ElementType;
  buttonPermission?: string;
  actions?: ReactNode;
  onButtonClick?: () => void;
}

export function ListHeader({
  title,
  subtitle,
  buttonTitle,
  buttonIcon: ButtonIcon = Add,
  buttonPermission,
  actions,
  onButtonClick,
}: IListHeaderProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        ) : null}
      </Box>
      {actions || (
        <>
          {buttonPermission ? (
            <Can permissions={[buttonPermission]}>
              <Button
                variant="contained"
                startIcon={<ButtonIcon />}
                onClick={onButtonClick}
              >
                {buttonTitle || 'Novo'}
              </Button>
            </Can>
          ) : (
            <Button
              variant="contained"
              startIcon={<ButtonIcon />}
              onClick={onButtonClick}
            >
              {buttonTitle || 'Novo'}
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
