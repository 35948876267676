import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as zod from 'zod';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import { EmptyData } from '@/components/common';
import { PaymentTerm } from '@/@types/payment-term';
import { TDocumentType } from '@/@types/document-type';
import { PaymentTermInstallmentsList } from '../PaymentTermInstallmentsList';

const paymentTermFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome da condição de pagamento')
    .transform((name) => name.trim().toUpperCase()),
  installments: zod
    .array(
      zod.object({
        id: zod.coerce.number(),
        documentTypeId: zod.string(),
        daysAmount: zod.coerce.number().min(0, 'Tipo de documento inválido'),
        documentType: zod.object({ name: zod.string() }),
        handleEditInstallment: zod.function(),
        handleRemoveSelectedInstallment: zod.function(),
      })
    )
    .min(1, 'Informe o tipo de documento'),
});

export type PaymentTermFormData = zod.infer<typeof paymentTermFormSchema>;

type SelectedInstallment = {
  id: number;
  documentTypeId: string;
  daysAmount: number;
  documentType: { name: string };
  // eslint-disable-next-line no-unused-vars
  handleEditInstallment: (installment: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveSelectedInstallment: (installmentId: any) => void;
};

interface IPaymentTermFormProps {
  paymentTerm?: PaymentTerm;
  documentsTypes: TDocumentType[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: PaymentTermFormData) => void;
  onCancel: () => void;
}

export function PaymentTermForm({
  paymentTerm,
  documentsTypes,
  isLoading,
  onSubmit,
  onCancel,
}: IPaymentTermFormProps) {
  const [selectedInstallment, setSelectedInstallment] = useState<
    SelectedInstallment | undefined
  >();
  const [isEditingInstallment, setIsEditingInstallment] = useState(false);

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<PaymentTermFormData>({
    resolver: zodResolver(paymentTermFormSchema),
    defaultValues: {
      name: paymentTerm?.name || '',
      installments: paymentTerm?.installments || [],
    },
  });

  const currentInstallments = getValues('installments');

  const getNextInstallmentId = () => {
    const lastId = currentInstallments.length;
    return lastId + 1;
  };

  const handleEditInstallment = (documentTypeToEdit: any) => {
    setIsEditingInstallment(true);
    setSelectedInstallment(documentTypeToEdit);
  };

  const handleRemoveSelectedInstallment = (installmentId: number) => {
    setValue(
      'installments',
      [
        ...currentInstallments.filter(
          (installment) => installment.id !== installmentId
        ),
      ],
      {
        shouldValidate: true,
      }
    );
  };

  const handleSelectInstallment = (id: string | number) => {
    const findInstallment = documentsTypes.find(
      (documentType) => documentType.id === id
    );

    if (findInstallment) {
      setSelectedInstallment({
        id: getNextInstallmentId(),
        documentTypeId: findInstallment.id,
        daysAmount: 0,
        documentType: {
          name: findInstallment.name,
        },
        handleEditInstallment,
        handleRemoveSelectedInstallment,
      });
    }
  };

  const handleChangeSelectedInstallmentDaysAmount = (value: string) => {
    setSelectedInstallment({
      ...selectedInstallment,
      daysAmount: Number(value),
    } as SelectedInstallment);
  };

  const handleAddSelectedInstallment = () => {
    if (!selectedInstallment) return;

    const installmentExists = currentInstallments.find(
      (installment) => installment.id === selectedInstallment?.id
    );

    if (installmentExists) {
      const updatedInstallments = currentInstallments.map((installment) => {
        if (installment.id === installmentExists.id) {
          return selectedInstallment;
        }

        return installment;
      });

      setValue('installments', [...updatedInstallments], {
        shouldValidate: true,
      });
    } else {
      setValue(
        'installments',
        [...currentInstallments, { ...selectedInstallment }],
        {
          shouldValidate: true,
        }
      );
    }

    setSelectedInstallment(undefined);
    setIsEditingInstallment(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Nome"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={isLoading}
            {...register('name')}
          />
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <Typography variant="body1" fontWeight="bold">
              Parcelas
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl fullWidth error={!!errors.installments}>
              <Autocomplete
                options={documentsTypes}
                getOptionLabel={(option) => option.name}
                noOptionsText="Nenhum registro encontrado"
                autoHighlight
                value={
                  selectedInstallment
                    ? documentsTypes?.find(
                        (documentType) =>
                          documentType.id === selectedInstallment.documentTypeId
                      )
                    : null
                }
                onChange={(event: any, newValue) =>
                  handleSelectInstallment(newValue ? newValue.id : '')
                }
                disabled={isEditingInstallment}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de Documento"
                    error={!!errors.installments}
                  />
                )}
              />
              {!!errors.installments && (
                <FormHelperText>{errors.installments.message}</FormHelperText>
              )}
            </FormControl>
            <TextField
              type="number"
              label="Prazo (Dias)"
              value={
                selectedInstallment?.daysAmount
                  ? Number(selectedInstallment?.daysAmount)
                  : 0
              }
              onChange={({ target }) =>
                handleChangeSelectedInstallmentDaysAmount(target.value)
              }
              disabled={!selectedInstallment?.documentTypeId || isLoading}
            />
            <Tooltip title="Adicionar">
              <Box display="flex" alignItems="center">
                <IconButton
                  color="primary"
                  size="large"
                  onClick={handleAddSelectedInstallment}
                  disabled={!selectedInstallment}
                >
                  <Add />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
          <Box
            border={`1px solid ${theme.palette.grey[400]}`}
            borderRadius={1}
            bgcolor={theme.palette.grey[200]}
          >
            {currentInstallments?.length ? (
              <PaymentTermInstallmentsList
                installments={currentInstallments}
                actions={{
                  handleEditInstallment,
                  handleRemoveSelectedInstallment,
                }}
              />
            ) : (
              <EmptyData sx={{ height: '10rem' }} />
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
