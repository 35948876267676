import { TablePagination as MuiTablePagination } from '@mui/material';

export interface ITablePaginationProps {
  totalCount: number;
  perPage: number;
  page: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (value: number) => void;
  // eslint-disable-next-line no-unused-vars
  onPerPageChange: (value: number) => void;
}

export function TablePagination({
  totalCount,
  perPage,
  page,
  onPageChange,
  onPerPageChange,
}: ITablePaginationProps) {
  return (
    <MuiTablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 25, 50]}
      count={totalCount || 0}
      rowsPerPage={perPage}
      page={page - 1}
      SelectProps={{
        inputProps: {
          'aria-label': 'linhas por página',
        },
      }}
      labelRowsPerPage="Registros"
      onPageChange={(_, newPage) => onPageChange(newPage)}
      onRowsPerPageChange={({ target }) =>
        onPerPageChange(Number(target.value))
      }
    />
  );
}
