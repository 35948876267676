import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { Output, OutputType } from '@/@types/outputs';
import { Product } from '@/@types/product';
import { Operation } from '@/@types/operation';
import { Customer } from '@/@types/customer';
import { Staff } from '@/@types/staff';
import { PriceTable } from '@/@types/price-table';
import { PaymentTerm } from '@/@types/payment-term';
import { TDocumentType } from '@/@types/document-type';
import { Settings } from '@/@types/settings';
import { OutputStatusLabel } from '../OutputStatusLabel';
import { OutputFormPlaceholder } from '../OutputForm/OutputFormPlaceholder';
import { OutputForm, OutputFormData } from '../OutputForm';

interface IOutputModalProps {
  outputType: OutputType;
  title?: string;
  output?: Output;
  products: Product[];
  operations: Operation[];
  customers: Customer[];
  staffs: Staff[];
  priceTables: PriceTable[];
  paymentTerms: PaymentTerm[];
  documentTypes: TDocumentType[];
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  settings?: Settings;
  defaultStaffId?: string;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: OutputFormData) => void;
}

export function OutputModal({
  outputType,
  title = 'Venda',
  output,
  products,
  operations,
  customers,
  staffs,
  priceTables,
  paymentTerms,
  documentTypes,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isFetching,
  settings,
  defaultStaffId,
  isSubmitting,
}: IOutputModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      closeOnBackdropClick={false}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ p: 2 }}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {output ? `Alterar ${title}` : `Nova ${title}`}
        <Box pr={8}>
          <OutputStatusLabel
            isFinished={!!output?.isFinished}
            situation={output?.situation}
            deletedAt={output?.deletedAt}
          />
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <OutputFormPlaceholder />
      ) : (
        <OutputForm
          outputType={outputType}
          output={output}
          products={products}
          operations={operations}
          customers={customers}
          staffs={staffs}
          priceTables={priceTables}
          paymentTerms={paymentTerms}
          documentTypes={documentTypes}
          isLoading={isSubmitting}
          settings={settings}
          defaultStaffId={defaultStaffId}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
