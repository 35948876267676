import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { TDocumentType } from '@/@types/document-type';
import { DocumentTypesTable } from '../DocumentTypesTable';
import { DocumentTypesTableColumns } from '../DocumentTypesTable/DocumentTypesTableColumns';

type TDocumentTypesData = {
  documentTypes: TDocumentType[];
  totalCount: number;
};

interface IDocumentTypesListProps {
  isLoading: boolean;
  isError: boolean;
  data: TDocumentTypesData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof TDocumentType;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function DocumentTypesList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IDocumentTypesListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder headers={['Nome', 'Criado em', 'Ações']} />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados dos tipos de documento." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <DocumentTypesTable
        columns={DocumentTypesTableColumns}
        data={data?.documentTypes || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
