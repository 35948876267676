import { Entry } from '@/@types/entries';
import { CustomMenu, Modal } from '@/components/common';
import { convertNumberToMoney, formatDate } from '@/utils';
import { Close, Print } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { EntryPrint, EntryPrintSimple } from '../EntryPrint';
import { EntryStatusLabelBullet } from '../EntryStatusLabelBullet';
import { EntryViewModalPlaceholder } from './EntryViewModalPlaceholder';

interface IEntryViewModalProps {
  entry?: Entry;
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  onClose: () => void;
}

export function EntryViewModal({
  entry,
  isOpen,
  onClose,
  isLoading,
  isFetching,
}: IEntryViewModalProps) {
  const [printMenuElement, setPrintMenuElement] = useState<null | HTMLElement>(
    null
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const entryPrintRef = useRef(null);
  const entryPrintSimpleRef = useRef(null);

  const handlePrintEntry = useReactToPrint({
    content: () => entryPrintRef.current,
  });

  const handlePrintEntrySimple = useReactToPrint({
    content: () => entryPrintSimpleRef.current,
  });

  return (
    <>
      <Modal open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ p: 2 }}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {isLoading ? (
            <Skeleton width={isMobile ? '80%' : '50%'} height="3rem" />
          ) : (
            entry?.operation.name
          )}
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="print"
              onClick={(event) => setPrintMenuElement(event.currentTarget)}
            >
              <Print />
            </IconButton>
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        {isFetching ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : null}
        {isLoading ? (
          <EntryViewModalPlaceholder />
        ) : (
          <DialogContent dividers sx={{ py: 3, px: 2 }}>
            <Stack spacing={3}>
              <Stack spacing={{ xs: 1, sm: 0.5 }}>
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <Typography variant="body1" fontWeight="bold">
                    Informações
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2">Número:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    #{entry?.number}
                  </Typography>
                </Stack>
                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2">Data:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {formatDate(entry?.issueDate!)}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {`\\ Entrada: ${formatDate(entry?.entryDate!)}`}
                  </Typography>
                </Stack>
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Fornecedor:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {entry?.vendor.name}
                  </Typography>
                </Stack>
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Colaborador:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {entry?.staff?.name || ''}
                  </Typography>
                </Stack>
                {entry?.note && (
                  <Stack
                    spacing={{ xs: 0, sm: 0.5 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    <Typography variant="body2">Observações:</Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {entry?.note || ''}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Criado por:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {entry?.user?.name || ''}
                  </Typography>
                </Stack>
                <Stack spacing={0.5} alignItems="center" direction="row">
                  <Typography variant="body2">Status:</Typography>
                  <EntryStatusLabelBullet
                    isFinished={entry?.isFinished!}
                    deletedAt={entry?.deletedAt}
                  />
                </Stack>
              </Stack>
              <Stack spacing={{ xs: 1, sm: 0.5 }}>
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <Typography variant="body1" fontWeight="bold">
                    Produtos
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Grid container rowSpacing={0.5}>
                  {entry?.products.map((product) => (
                    <React.Fragment key={product.id}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2">
                          {product.entryProduct.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="body2">
                          {product.productQuantity}{' '}
                          {product.entryUnit.abbreviation} x{' '}
                          {convertNumberToMoney(product.unitaryValue)} ={' '}
                          {convertNumberToMoney(product.subtotal)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={1}
                  pt={1}
                >
                  {entry?.totalGross !== entry?.total ? (
                    <>
                      <Typography variant="body2">
                        Subtotal Bruto:{' '}
                        {convertNumberToMoney(entry?.totalGross!)}
                      </Typography>
                      <Typography variant="body2">
                        Desconto: {convertNumberToMoney(entry?.totalDiscount!)}
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        Total: {convertNumberToMoney(entry?.total!)}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      Total: {convertNumberToMoney(entry?.total!)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        )}
      </Modal>
      <Box display="none">
        <EntryPrint entry={entry} containerRef={entryPrintRef} />
        <EntryPrintSimple entry={entry} containerRef={entryPrintSimpleRef} />
      </Box>
      <CustomMenu
        anchorEl={printMenuElement}
        onClose={() => setPrintMenuElement(null)}
        menuItems={[
          { title: 'Imprimir (Bobina)', onClick: handlePrintEntry },
          {
            title: 'Imprimir (Bobina Simples)',
            onClick: handlePrintEntrySimple,
          },
        ]}
      />
    </>
  );
}
