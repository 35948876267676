import { User } from '@/@types/user';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { Typography } from '@mui/material';
import { UsersTableActions } from './UsersTableActions';

export const UsersTableColumns: ColumnDefinitionType<User>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
    render: (user) => (
      <>
        <Typography variant="body2">{user.name}</Typography>
        <Typography variant="body2" color="GrayText" component="span">
          {user.email}
        </Typography>
      </>
    ),
  },
  {
    key: 'staff',
    header: 'Colaborador',
    sort: 'staff.name',
    render: (user) => user.staff?.name || '',
  },
  {
    key: 'role',
    header: 'Perfil',
    sort: 'role.name',
    render: (user) => user.role?.name || '',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: UsersTableActions,
  },
];
