import { TDocumentType } from '@/@types/document-type';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { DocumentTypesTableActions } from './DocumentTypesTableActions';

export const DocumentTypesTableColumns: ColumnDefinitionType<TDocumentType>[] =
  [
    {
      key: 'name',
      header: 'Nome',
      sort: 'name',
    },
    {
      key: 'createdAt',
      header: 'Criado em',
      sort: 'created_at',
    },
    {
      key: 'id',
      header: 'Ações',
      render: DocumentTypesTableActions,
    },
  ];
