import { Unit } from '@/@types/unit';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { UnitsTableActions } from './UnitsTableActions';

export const UnitsTableColumns: ColumnDefinitionType<Unit>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
  },
  {
    key: 'abbreviation',
    header: 'Abreviação',
    sort: 'abbreviation',
  },
  {
    key: 'quantity',
    header: 'Quantidade',
    sort: 'quantity',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: UnitsTableActions,
  },
];
