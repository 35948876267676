import { TEntryProduct } from '@/@types/entries';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { TableActions } from '@/components/common/Table/TableActions';
import { convertNumberToMoney } from '@/utils';

export const EntryProductsTableColumns: ColumnDefinitionType<TEntryProduct>[] =
  [
    {
      key: 'id',
      header: 'Item',
      sort: '',
    },
    {
      key: 'entryProduct',
      header: 'Produto',
      sort: '',
      render: (entryProduct) => entryProduct.entryProduct?.name || '',
    },
    {
      key: 'entryUnit',
      header: 'Unidade',
      sort: '',
      render: (entryProduct) => entryProduct.entryUnit?.abbreviation || '',
    },
    {
      key: 'productQuantity',
      header: 'Quantidade',
      sort: '',
      render: (entryProduct) => entryProduct.productQuantity,
    },
    {
      key: 'priceGross',
      header: 'Preço Bruto',
      sort: '',
      render: (entryProduct) => convertNumberToMoney(entryProduct.priceGross),
    },
    {
      key: 'discount',
      header: 'Desconto',
      sort: '',
      render: (entryProduct) => convertNumberToMoney(entryProduct.discount),
    },
    {
      key: 'subtotalProduct',
      header: 'Subtotal Produto',
      sort: '',
      render: (entryProduct) =>
        convertNumberToMoney(entryProduct.subtotalProduct),
    },
    {
      key: 'unitaryValue',
      header: 'Valor Unitário',
      sort: '',
      render: (entryProduct) => convertNumberToMoney(entryProduct.unitaryValue),
    },
    {
      key: 'id',
      header: 'Ações',
      sort: '',
      render: (entryProduct) => (
        <TableActions
          id={entryProduct.productId}
          editButtonPermission="purchases_create"
          deleteButtonPermission="purchases_create"
          restoreButtonPermission="purchases_restore"
          shouldDisableActions={entryProduct.isEntryFinished}
          onEdit={() => entryProduct.handleEditProduct(entryProduct)}
          onDelete={() =>
            entryProduct.handleRemoveSelectedProduct(entryProduct.productId)
          }
        />
      ),
    },
  ];
