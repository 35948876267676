import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import React from 'react';
import { Output } from '@/@types/outputs';
import { OutputsTable } from '../OutputsTable';

type TOutputsData = {
  outputs: Output[];
  totalCount: number;
};

interface IOutputsListProps {
  columns: ColumnDefinitionType<Output>[];
  isLoading: boolean;
  isError: boolean;
  data: TOutputsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof Output;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
  selected?: string[];
  // eslint-disable-next-line no-unused-vars
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (event: React.MouseEvent<unknown>, id: string) => void;
}

export function OutputsList({
  columns,
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
  selected,
  onSelectAll,
  onItemClick,
}: IOutputsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder
          headers={[
            'Data',
            'Cliente',
            'Colaborador',
            'Operação',
            'Total',
            'Status',
            'Criado em',
            'Ações',
          ]}
        />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados das vendas." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <OutputsTable
        columns={columns}
        data={data?.outputs || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
        selected={selected}
        selectedKey="id"
        onSelectAll={onSelectAll}
        onItemClick={onItemClick}
      />
    </ResponseDataHandle>
  );
}
