import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { MouseEvent, useContext, useState } from 'react';
import { AuthContext } from '@/contexts/AuthContext';
import { SidebarDrawerContext } from '@/contexts/SidebarDrawerContext';
import { stringAvatar } from '@/utils';
import Link from 'next/link';
import { ExpandMore } from '@mui/icons-material';

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function Header() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isDrawerOpen, openDrawer } = useContext(SidebarDrawerContext);

  const { isAuthenticated, user, clientSideSignOut } = useContext(AuthContext);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    openDrawer();
  };

  return (
    <AppBar position="fixed" open={isDrawerOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(isDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          display={{
            xs: isDrawerOpen ? 'none' : 'block',
            sm: 'block',
          }}
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Água Amigão
        </Typography>
        {isAuthenticated && (
          <Box sx={{ flexGrow: 0, marginLeft: { xs: 'auto', sm: 0 } }}>
            <Tooltip title="Menu do usuário">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                <Avatar
                  alt={user?.name || 'Avatar do usuário'}
                  {...stringAvatar(user?.name as string)}
                />
                <ExpandMore />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                '& .MuiMenuItem-root': {
                  '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    marginRight: theme.spacing(1.5),
                  },
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/profile">
                  <Box display="flex" alignItems="center">
                    <AccountCircleIcon
                      sx={{ color: theme.palette.text.secondary }}
                    />
                    Perfil
                  </Box>
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={clientSideSignOut}
                sx={{ color: theme.palette.error.main }}
              >
                <LogoutIcon sx={{ color: theme.palette.error.main }} />
                Sair
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
