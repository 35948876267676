export const CPF_MASK = '999.999.999-99';
export const CNPJ_MASK = '99.999.999/9999-99';
export const PHONE_MASK = '(99) 9999-9999';
export const CELLPHONE_MASK = '(99) 99999-9999';
export const UNIDENTIFIED_PHONE_MASK = '(99) 9999999999';
export const CEP_MASK = '99999-999';

export type IMaskProps =
  | typeof CPF_MASK
  | typeof CNPJ_MASK
  | typeof PHONE_MASK
  | typeof CELLPHONE_MASK
  | string;
