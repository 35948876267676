/* eslint-disable no-nested-ternary */
import { Situation } from '@/@types/situation';
import { useSettings } from '@/modules/settings/hooks';
import { capitalizeWord } from '@/utils/capitalizeWord';
import { Chip } from '@mui/material';

interface IOutputStatusLabelProps {
  isFinished: boolean;
  situation?: Situation | null;
  deletedAt?: string;
}

export function OutputStatusLabel({
  isFinished,
  situation,
  deletedAt,
}: IOutputStatusLabelProps) {
  const settings = useSettings({});

  const transitSituationId = settings.data?.settings?.salesTransitSituationId;
  const isTransitSituation = situation?.id === transitSituationId;

  return (
    <Chip
      color={
        deletedAt
          ? 'default'
          : isFinished
          ? 'primary'
          : isTransitSituation
          ? 'secondary'
          : 'warning'
      }
      label={
        deletedAt
          ? 'Excluído'
          : isFinished
          ? 'Finalizado'
          : situation
          ? capitalizeWord(situation?.name || '')
          : 'Pendente'
      }
    />
  );
}
