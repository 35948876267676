import { Entry, EntryType } from '@/@types/entries';
import { Operation } from '@/@types/operation';
import { Product } from '@/@types/product';
import { Settings } from '@/@types/settings';
import { Staff } from '@/@types/staff';
import { Vendor } from '@/@types/vendor';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { EntryForm, EntryFormData } from '../EntryForm';
import { EntryFormPlaceholder } from '../EntryForm/EntryFormPlaceholder';
import { EntryStatusLabel } from '../EntryStatusLabel';

interface IEntryModalProps {
  entryType: EntryType;
  title?: string;
  entry?: Entry;
  products: Product[];
  operations: Operation[];
  vendors: Vendor[];
  staffs: Staff[];
  isOpen: boolean;
  isLoading: boolean;
  settings?: Settings;
  defaultStaffId?: string;
  isFetching: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: EntryFormData) => void;
}

export function EntryModal({
  entryType,
  title = 'Compra',
  entry,
  products,
  operations,
  vendors,
  staffs,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  settings,
  defaultStaffId,
  isFetching,
  isSubmitting,
}: IEntryModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      closeOnBackdropClick={false}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ p: 2 }}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {entry ? `Alterar ${title}` : `Nova ${title}`}
        <Box pr={8}>
          <EntryStatusLabel
            isFinished={!!entry?.isFinished}
            deletedAt={entry?.deletedAt}
          />
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <EntryFormPlaceholder />
      ) : (
        <EntryForm
          entryType={entryType}
          entry={entry}
          products={products}
          operations={operations}
          vendors={vendors}
          staffs={staffs}
          isLoading={isSubmitting}
          settings={settings}
          defaultStaffId={defaultStaffId}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
