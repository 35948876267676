import { Staff } from '@/@types/staff';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { StaffsTableActions } from './StaffsTableActions';

export const StaffsTableColumns: ColumnDefinitionType<Staff>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: StaffsTableActions,
  },
];
