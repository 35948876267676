import { Settings } from '@/@types/settings';
import { api } from '@/services/apiClient';
import { useQuery } from '@tanstack/react-query';

interface GetSettingsProps {
  enabled?: boolean;
}

type GetSettingsResponse = {
  settings: Settings;
};

export async function getSettings(): Promise<GetSettingsResponse> {
  const { data } = await api.get('/settings');

  const settings = {
    productUnitId: data.product_unit_id,
    productCategoryId: data.product_category_id,
    saleOperationId: data.sale_operation_id,
    telesalesOperationId: data.telesales_operation_id,
    saleCustomerId: data.sale_customer_id,
    salePaymentTermId: data.sale_payment_term_id,
    purchaseOperationId: data.purchase_operation_id,
    purchaseVendorId: data.purchase_vendor_id,
    otherOutputOperationId: data.other_output_operation_id,
    otherOutputCustomerId: data.other_output_customer_id,
    otherOutputPaymentTermId: data.other_output_payment_term_id,
    otherEntryOperationId: data.other_entry_operation_id,
    otherEntryVendorId: data.other_entry_vendor_id,
    salesInitialSituationId: data.sales_initial_situation_id,
    salesTransitSituationId: data.sales_transit_situation_id,
    salesFinishedSituationId: data.sales_finished_situation_id,
  } as Settings;

  return { settings };
}

export function useSettings({ enabled = true }: GetSettingsProps) {
  return useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettings(),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
  });
}
