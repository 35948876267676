import { Role } from '@/@types/roles';
import { Staff } from '@/@types/staff';
import { User } from '@/@types/user';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { UserForm, UserFormData } from '../UserForm';
import { UserFormPlaceholder } from '../UserForm/UserFormPlaceholder';

interface IUserModalProps {
  user?: User;
  roles: Role[];
  staffs: Staff[];
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: UserFormData) => void;
}

export function UserModal({
  user,
  roles,
  staffs,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isFetching,
  isSubmitting,
}: IUserModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {user ? 'Alterar Usuário' : 'Novo Usuário'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <UserFormPlaceholder />
      ) : (
        <UserForm
          user={user}
          roles={roles}
          staffs={staffs}
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
