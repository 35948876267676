interface HeaderProps {
  title?: string;
}

export function Header({
  title = 'AGUA AMIGAO - (82) 3327-1052',
}: HeaderProps) {
  return (
    <div>
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{title}</p>
    </div>
  );
}
