import { TOutputProduct } from '@/@types/outputs';
import { Table } from '@/components/common';
import { OutputProductsTableColumns } from './OutputProductsTableColumns';

interface IOutputProductsListProps {
  products: TOutputProduct[];
  actions: object;
}

export function OutputProductsList({
  products,
  actions,
}: IOutputProductsListProps) {
  return (
    <Table
      data={products}
      columns={OutputProductsTableColumns}
      additionalData={actions}
      maxHeight={255}
    />
  );
}
