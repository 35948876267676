import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface IDocumentTypesTableActionsProps {
  id: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditDocumentType: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteDocumentType: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestoreDocumentType: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditDocumentTypeMouseEnter: (id: string) => void;
}

export function DocumentTypesTableActions({
  id,
  deletedAt,
  onEditDocumentType,
  onDeleteDocumentType,
  onRestoreDocumentType,
  onEditDocumentTypeMouseEnter,
}: IDocumentTypesTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={['document_types_update']}>
        <Tooltip title="Alterar">
          <IconButton
            onMouseEnter={() => onEditDocumentTypeMouseEnter(id)}
            onClick={() => onEditDocumentType(id)}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Can>
      {deletedAt ? (
        <Can permissions={['document_types_restore']}>
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => onRestoreDocumentType(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RestoreFromTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={['document_types_delete']}>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => onDeleteDocumentType(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
