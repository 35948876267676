import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { PaymentTerm } from '@/@types/payment-term';
import { PaymentTermsTable } from '../PaymentTermsTable';
import { PaymentTermsTableColumns } from '../PaymentTermsTable/PaymentTermsTableColumns';

type TPaymentTermsData = {
  paymentTerms: PaymentTerm[];
  totalCount: number;
};

interface IPaymentTermsListProps {
  isLoading: boolean;
  isError: boolean;
  data: TPaymentTermsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof PaymentTerm;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function PaymentTermsList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IPaymentTermsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder headers={['Nome', 'Criado em', 'Ações']} />
      }
      isError={isError}
      errorComponent={
        <Box my={10}>
          <ErrorList text="Falha ao obter os dados das condições de pagamento." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box my={10}>
          <EmptyData />
        </Box>
      }
    >
      <PaymentTermsTable
        columns={PaymentTermsTableColumns}
        data={data?.paymentTerms || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
