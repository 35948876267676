import { TDocumentType } from '@/@types/document-type';
import { Table } from '@/components/common';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';

export interface IDocumentTypesTableProps {
  data: TDocumentType[];
  columns: ColumnDefinitionType<TDocumentType>[];
  sort?: 'desc' | 'asc';
  sortBy?: keyof TDocumentType;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function DocumentTypesTable({
  data,
  columns,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IDocumentTypesTableProps) {
  return (
    <Table
      data={data}
      columns={columns}
      additionalData={actions}
      sort={sort}
      sortBy={sortBy}
      onSort={onSort}
      pagination={pagination}
    />
  );
}
