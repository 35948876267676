import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface IPaymentTermsTableActionsProps {
  id: string;
  name: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditPaymentTerm: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeletePaymentTerm: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestorePaymentTerm: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditPaymentTermMouseEnter: (id: string) => void;
}

export function PaymentTermsTableActions({
  id,
  name,
  deletedAt,
  onEditPaymentTerm,
  onDeletePaymentTerm,
  onRestorePaymentTerm,
  onEditPaymentTermMouseEnter,
}: IPaymentTermsTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {name !== 'PERSONALIZADA' && (
        <>
          <Can permissions={['payment_terms_update']}>
            <Tooltip title="Alterar">
              <IconButton
                onMouseEnter={() => onEditPaymentTermMouseEnter(id)}
                onClick={() => onEditPaymentTerm(id)}
                size="small"
                sx={{
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </Can>
          {deletedAt ? (
            <Can permissions={['payment_terms_restore']}>
              <Tooltip title="Restaurar">
                <IconButton
                  onClick={() => onRestorePaymentTerm(id)}
                  size="small"
                  sx={{
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <RestoreFromTrash fontSize="small" />
                </IconButton>
              </Tooltip>
            </Can>
          ) : (
            <Can permissions={['payment_terms_delete']}>
              <Tooltip title="Excluir">
                <IconButton
                  onClick={() => onDeletePaymentTerm(id)}
                  size="small"
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main,
                    },
                  }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Can>
          )}
        </>
      )}
    </Stack>
  );
}
