import React, { ReactNode } from 'react';

import { useCan } from '@/modules/core/hooks';

interface ICanProps {
  children: ReactNode;
  permissions?: string[];
}

export function Can({ children, permissions }: ICanProps) {
  const userCanSeeComponent = useCan({ permissions });

  if (!userCanSeeComponent) return null;

  return <>{children}</>;
}
