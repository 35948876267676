import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import * as zod from 'zod';
import { Unit } from '@/@types/unit';

const unitFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome da unidade')
    .transform((name) => name.trim().toUpperCase()),
  abbreviation: zod
    .string()
    .min(2, 'Informe a abreviação da unidade. Exemplo: UN, CX, PC, PCT')
    .transform((abbreviation) => abbreviation.trim().toUpperCase()),
  quantity: zod.coerce.number().min(1, 'Quantidade inválida'),
});

export type UnitFormData = zod.infer<typeof unitFormSchema>;

interface IUnitFormProps {
  unit?: Unit;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: UnitFormData) => void;
  onCancel: () => void;
}

export function UnitForm({
  unit,
  isLoading,
  onSubmit,
  onCancel,
}: IUnitFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UnitFormData>({
    resolver: zodResolver(unitFormSchema),
    defaultValues: {
      name: unit?.name || '',
      abbreviation: unit?.abbreviation || '',
      quantity: unit?.quantity || 1,
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Nome"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={isLoading}
            {...register('name')}
          />
          <TextField
            type="text"
            label="Abreviação"
            error={!!errors.abbreviation}
            helperText={errors.abbreviation?.message}
            disabled={isLoading}
            {...register('abbreviation')}
          />
          <TextField
            type="number"
            label="Quantidade"
            error={!!errors.quantity}
            helperText={errors.quantity?.message}
            disabled={isLoading}
            {...register('quantity')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
