import { TDocumentType } from '@/@types/document-type';
import { PaymentTerm } from '@/@types/payment-term';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { PaymentTermForm, PaymentTermFormData } from '../PaymentTermForm';
import { PaymentTermFormPlaceholder } from '../PaymentTermForm/PaymentTermFormPlaceholder';

interface IPaymentTermModalProps {
  paymentTerm?: PaymentTerm;
  documentsTypes: TDocumentType[];
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: PaymentTermFormData) => void;
}

export function PaymentTermModal({
  paymentTerm,
  documentsTypes,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isFetching,
  isSubmitting,
}: IPaymentTermModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {paymentTerm
          ? 'Alterar Condição de Pagamento'
          : 'Nova Condição de Pagamento'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <PaymentTermFormPlaceholder />
      ) : (
        <PaymentTermForm
          paymentTerm={paymentTerm}
          documentsTypes={documentsTypes}
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
