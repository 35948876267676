import React from 'react';

import {
  Header,
  PrintInfoSimple,
  Separator,
} from '@/components/common/PosPrint';
import { Output } from '@/@types/outputs';

interface IOutputPrintProps {
  output?: Output;
  containerRef: any | null;
}

export function OutputPrintSimple({ output, containerRef }: IOutputPrintProps) {
  return (
    <div
      ref={containerRef}
      style={{
        printColorAdjust: 'economy',
        width: '7.2cm',
        color: 'black',
        padding: '0.25cm',
        fontFamily: 'Arial',
        fontSize: '11px',
      }}
    >
      <Separator />
      <Header />
      <Header
        title={`${output?.operation.number} - ${output?.operation.name}`}
      />
      <Separator />
      <PrintInfoSimple
        number={output?.number}
        date={output?.issueDate}
        staff={output?.staff}
        note={output?.note}
      />
      <Separator />
      <div style={{ fontWeight: 'bold' }}>PRODUTOS</div>
      <Separator />
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          {output?.products?.map((product, index) => (
            <tr
              key={product.id}
              style={{
                borderBottom:
                  index + 1 < output?.products?.length
                    ? '1px solid #333333'
                    : '',
              }}
            >
              <td>{product.outputProduct.name}</td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                {product.productQuantity.toFixed(2)}{' '}
                {product.outputUnit.abbreviation}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Separator />
      <br />
      <div
        style={{ textAlign: 'center', marginTop: '16px', marginBottom: '24px' }}
      >
        <p>________________________________</p>
        <p>{output?.user.name}</p>
      </div>
      <br />
    </div>
  );
}
