import { ICreatePriceTableProduct, PriceTable } from '@/@types/price-table';
import { api } from '@/services/apiClient';
import { queryClient } from '@/services/queryClient';
import { formatDate } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import {
  transformPriceTableProductsToBackend,
  transformPriceTableProductsToFrontend,
} from '../utils';

interface CreatePriceTableProps {
  name: string;
  products: ICreatePriceTableProduct[];
}

interface UpdatePriceTableProps {
  id: string;
  name: string;
  products: ICreatePriceTableProduct[];
}

interface DeletePriceTableProps {
  id: string;
}

interface RestorePriceTableProps {
  id: string;
}

type CreatePriceTableResponse = PriceTable;
type UpdatePriceTableResponse = PriceTable;
type RestorePriceTableResponse = PriceTable;

async function createPriceTable({
  name,
  products,
}: CreatePriceTableProps): Promise<CreatePriceTableResponse> {
  const transformedProducts = transformPriceTableProductsToBackend(products);

  const { data } = await api.post('/price_tables', {
    name,
    products: transformedProducts,
  });

  return data;
}

async function updatePriceTable({
  id,
  name,
  products,
}: UpdatePriceTableProps): Promise<UpdatePriceTableResponse> {
  const transformedProducts = transformPriceTableProductsToBackend(products);

  const { data } = await api.put('/price_tables', {
    id,
    name,
    products: transformedProducts,
  });

  return data;
}

async function deletePriceTable({ id }: DeletePriceTableProps): Promise<void> {
  await api.delete(`/price_tables/${id}`);
}

async function restorePriceTable({
  id,
}: RestorePriceTableProps): Promise<RestorePriceTableResponse> {
  const { data } = await api.patch(`/price_tables/${id}`);

  return data;
}

export function usePriceTable() {
  const createPriceTableMutation = useMutation({
    mutationFn: ({ name, products }: CreatePriceTableProps) =>
      createPriceTable({
        name,
        products,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['priceTables']);
    },
  });

  const updatePriceTableMutation = useMutation({
    mutationFn: ({ id, name, products }: UpdatePriceTableProps) =>
      updatePriceTable({
        id,
        name,
        products,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['priceTables']);
      queryClient.invalidateQueries(['priceTable', data.id]);
    },
  });

  const deletePriceTableMutation = useMutation({
    mutationFn: ({ id }: DeletePriceTableProps) => deletePriceTable({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['priceTables']);
    },
  });

  const restorePriceTableMutation = useMutation({
    mutationFn: ({ id }: RestorePriceTableProps) => restorePriceTable({ id }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['priceTables']);
      queryClient.invalidateQueries(['priceTable', data.id]);
    },
  });

  async function prefetchPriceTable(priceTableId: string) {
    await queryClient.prefetchQuery(
      ['priceTable', priceTableId],
      async () => {
        const { data } = await api.get(`price_tables/${priceTableId}`);
        const transformedProducts = transformPriceTableProductsToFrontend(
          data.products
        );

        return {
          id: data.id,
          name: data.name,
          products: transformedProducts,
          createdAt: formatDate(data.created_at),
          deletedAt: data.deleted_at && formatDate(data.deleted_at),
          onEditPriceTable: () => {},
          onDeletePriceTable: () => {},
          onRestorePriceTable: () => {},
          onEditPriceTableMouseEnter: () => {},
        } as PriceTable;
      },
      {
        staleTime: 1000 * 60 * 1, // 1 minute
      }
    );
  }

  return {
    createPriceTableMutation,
    updatePriceTableMutation,
    deletePriceTableMutation,
    restorePriceTableMutation,
    prefetchPriceTable,
  };
}
