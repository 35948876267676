import { Checkbox, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { StyledTableCell } from '@/styles/staffs.styles';
import React from 'react';
import { ColumnDefinitionType } from '../table.utils';

interface ITableHeadersProps<T> {
  columns: ColumnDefinitionType<T>[];
  sortBy?: keyof T;
  sort?: 'desc' | 'asc';
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  rowCount?: number;
  numSelected?: number;
  // eslint-disable-next-line no-unused-vars
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function TableHeaders<T>({
  columns,
  sortBy,
  sort,
  onSort,
  rowCount,
  numSelected,
  onSelectAll,
}: ITableHeadersProps<T>) {
  return (
    <TableHead>
      <TableRow>
        {onSelectAll ? (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                numSelected !== undefined &&
                rowCount !== undefined &&
                numSelected > 0 &&
                numSelected < rowCount
              }
              checked={
                rowCount !== undefined &&
                rowCount > 0 &&
                numSelected === rowCount
              }
              onChange={onSelectAll}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </StyledTableCell>
        ) : null}
        {columns.map((column) => (
          <StyledTableCell
            key={column.header}
            sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
            align={column.align || 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel
              active={!!column.sort && sortBy === column.sort}
              hideSortIcon={!column.sort}
              direction={!!column.sort && sortBy === column.sort ? sort : 'asc'}
              onClick={() => (onSort ? onSort(column.sort as string) : {})}
            >
              {column.header}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
