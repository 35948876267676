import { useRouter } from 'next/router';
import { createContext, ReactNode, useEffect, useState } from 'react';

interface SidebarDrawerProviderProps {
  children: ReactNode;
}

type SidebarContextData = {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
};

export const SidebarDrawerContext = createContext({} as SidebarContextData);

export function SidebarDrawerProvider({
  children,
}: SidebarDrawerProviderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const router = useRouter();

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    closeDrawer();
  }, [router.asPath]);

  return (
    <SidebarDrawerContext.Provider
      value={{ isDrawerOpen, openDrawer, closeDrawer }}
    >
      {children}
    </SidebarDrawerContext.Provider>
  );
}
