import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { StockMovement } from '@/@types/stock-movement';
import { ProductStockMovementsTable } from '../ProductStockMovementsTable';
import { ProductStockMovementsTableColumns } from '../ProductStockMovementsTable/ProductStockMovementsTableColumns';

type TProductStockMovementsData = {
  stockMovements: StockMovement[];
  totalCount: number;
};

interface IProductStockMovementsListProps {
  isLoading: boolean;
  isError: boolean;
  data: TProductStockMovementsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof StockMovement;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function ProductStockMovementsList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IProductStockMovementsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder
          headers={[
            'Qtde Anterior',
            'Qtde Movimento',
            'Qtde Final',
            'Tipo',
            'Data',
          ]}
        />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados das movimentações do produto." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <ProductStockMovementsTable
        columns={ProductStockMovementsTableColumns}
        data={data?.stockMovements || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
