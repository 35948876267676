import { Staff } from '@/@types/staff';
import { formatDateTime } from '@/utils';

interface PrintInfoSimpleProps {
  number?: string | number;
  date?: string;
  staff?: Staff;
  note?: string;
}

export function PrintInfoSimple({
  number,
  date,
  staff,
  note,
}: PrintInfoSimpleProps) {
  return (
    <>
      <table cellSpacing="0" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>Número: {number}</td>
            <td style={{ textAlign: 'right' }}>
              Data: {formatDateTime(date as string)}
            </td>
          </tr>
        </tbody>
      </table>
      <ul style={{ listStyle: 'none' }}>
        <li>
          Colaborador.: {staff?.number} - {staff?.name}
        </li>
        <li>Observacoes: {note}</li>
      </ul>
    </>
  );
}
