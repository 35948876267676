import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { Unit } from '@/@types/unit';
import { UnitsTable } from '../UnitsTable';
import { UnitsTableColumns } from '../UnitsTable/UnitsTableColumns';

type TUnitsData = {
  units: Unit[];
  totalCount: number;
};

interface IUnitsListProps {
  isLoading: boolean;
  isError: boolean;
  data: TUnitsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof Unit;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function UnitsList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IUnitsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder
          headers={['Nome', 'Abreviação', 'Quantidade', 'Criado em', 'Ações']}
        />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados das unidades." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <UnitsTable
        columns={UnitsTableColumns}
        data={data?.units || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
