import { SearchOff } from '@mui/icons-material';
import { Box, BoxProps, Typography } from '@mui/material';

type EmptyDataProps = {
  message?: string;
} & BoxProps;

export function EmptyData({
  message = 'Nenhum registro encontrado.',
  ...props
}: EmptyDataProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box>
        <SearchOff
          fontSize="inherit"
          sx={{ color: 'GrayText', fontSize: '4rem' }}
        />
      </Box>
      <Typography variant="body1" color="gray">
        {message}
      </Typography>
    </Box>
  );
}
