import React from 'react';
import { printCharSizeArray } from './PosPrint.utils';

export function Separator() {
  return (
    <div>
      {printCharSizeArray.map((n) => (
        <React.Fragment key={n}>-</React.Fragment>
      ))}
    </div>
  );
}
