import { PriceTable } from '@/@types/price-table';
import { api } from '@/services/apiClient';
import { formatDate } from '@/utils';
import { useQuery } from '@tanstack/react-query';

interface GetPriceTablesProps {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sort?: string;
  deleted?: boolean;
  enabled?: boolean;
}

type GetPriceTablesResponse = {
  priceTables: PriceTable[];
  totalCount: number;
};

export async function getPriceTables({
  page,
  perPage,
  search,
  sortBy,
  sort,
  deleted,
}: GetPriceTablesProps): Promise<GetPriceTablesResponse> {
  const { data } = await api.get('/price_tables', {
    params: {
      page,
      per_page: perPage,
      search,
      sort_by: sortBy,
      sort: sort ? sort.toUpperCase() : undefined,
      deleted,
    },
  });

  const totalCount = data.total;

  const priceTables = data.content.map((priceTable: any) => ({
    id: priceTable.id,
    name: priceTable.name,
    createdAt: formatDate(priceTable.created_at, 'pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    deletedAt: priceTable.deleted_at && formatDate(priceTable.deleted_at),
  }));

  return { priceTables, totalCount };
}

export function usePriceTables({
  page = 0,
  perPage = 0,
  deleted,
  search,
  sortBy,
  sort,
  enabled = true,
}: GetPriceTablesProps) {
  return useQuery({
    queryKey: ['priceTables', { page, perPage, deleted, search, sortBy, sort }],
    queryFn: () =>
      getPriceTables({ page, perPage, deleted, search, sortBy, sort }),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
  });
}
