export const formatDate = (
  date: string,
  locale?: string | string[],
  format?: Intl.DateTimeFormatOptions
) => {
  return new Date(date).toLocaleDateString(
    locale || 'pt-BR',
    format || {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }
  );
};

export const formatDateTime = (
  date: string,
  locale?: string | string[],
  format?: Intl.DateTimeFormatOptions
) => {
  return new Date(date).toLocaleDateString(
    locale || 'pt-BR',
    format || {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }
  );
};

export const getTodayDateAsString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    date < 10 ? `0${date}` : date
  }`;
};

export const getFirstDayOfTheWeek = () => {
  const today = new Date();
  const day = today.getDay();

  const diff = today.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(today.setDate(diff));
};

export const getLastDayOfTheWeek = () => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - today.getDay() + 6));
};

export const createDateFromString = (date: string) => {
  const [year, month, day] = date.split('-');

  return new Date(Number(year), Number(month) - 1, Number(day));
};
