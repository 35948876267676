/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as zod from 'zod';
import { useSnackbar } from 'notistack';
import { useViaCepAPI } from '@/modules/core/hooks';
import React, { useEffect, useRef } from 'react';
import { Place } from '@mui/icons-material';
import { MaskedInput } from '@/components/common';
import {
  CELLPHONE_MASK,
  CEP_MASK,
  CNPJ_MASK,
  CPF_MASK,
  PHONE_MASK,
  UNIDENTIFIED_PHONE_MASK,
} from '@/components/common/MaskedInput/MaskedInput.utils';
import { Customer } from '@/@types/customer';
import { PriceTable } from '@/@types/price-table';

const customerFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe a razão social/nome do cliente')
    .transform((name) => name.trim().toUpperCase()),
  type: zod.string().min(2, 'Informe o tipo do cliente'),
  businessName: zod
    .string()
    .min(2, 'Informe o apelido/nome fantasia')
    .transform((businessName) => businessName.trim().toUpperCase()),
  employerNumber: zod.string().optional(),
  nationalIdentity: zod.string().optional(),
  postalCode: zod.string().optional(),
  streetName: zod
    .string()
    .optional()
    .transform((streetName) => streetName?.trim()?.toUpperCase()),
  streetNumber: zod.string().optional(),
  complement: zod
    .string()
    .optional()
    .transform((complement) => complement?.trim().toUpperCase()),
  landmark: zod
    .string()
    .optional()
    .transform((landmark) => landmark?.trim().toUpperCase()),
  neighborhood: zod
    .string()
    .optional()
    .transform((neighborhood) => neighborhood?.trim().toUpperCase()),
  city: zod
    .string()
    .optional()
    .transform((city) => city?.trim().toUpperCase()),
  phone: zod.string().optional(),
  email: zod.string().optional(),
  priceTableId: zod.string().optional(),
  note: zod.string().optional(),
  outputNote: zod.string().optional(),
});

export type CustomerFormData = zod.infer<typeof customerFormSchema>;

interface ICustomerFormProps {
  customer?: Customer;
  isLoading: boolean;
  priceTables: PriceTable[];
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: CustomerFormData) => void;
  onCancel: () => void;
}

const CUSTOMER_TYPES = [
  { id: 'PF', label: 'Pessoa Física' },
  { id: 'PJ', label: 'Pessoa Jurídica' },
];

export function CustomerForm({
  customer,
  isLoading,
  priceTables,
  onSubmit,
  onCancel,
}: ICustomerFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleKeyDown = async (
    e: React.KeyboardEvent,
    index: number,
    fieldName: keyof CustomerFormData,
    isTextArea = false
  ): Promise<void> => {
    const isAutoComplete = (e.target as any)?.ariaAutoComplete;

    if (!isAutoComplete) {
      setValue(fieldName, (e.target as HTMLInputElement).value);
    }

    if (isTextArea && !e.shiftKey) {
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();

      const isValid = await trigger(fieldName);

      if (isValid) {
        if (e.ctrlKey && submitButtonRef.current) {
          submitButtonRef.current.focus();
        } else {
          const nextField = inputRefs.current[index + 1];
          if (nextField) {
            nextField.focus();
          } else if (submitButtonRef.current) {
            submitButtonRef.current.focus();
          }
        }
      }
    }
  };

  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors, dirtyFields },
    watch,
    getValues,
    setValue,
  } = useForm<CustomerFormData>({
    resolver: zodResolver(customerFormSchema),
    defaultValues: {
      name: customer?.name || '',
      type: customer?.type || 'PF',
      businessName: customer?.businessName || '',
      employerNumber: customer?.employerNumber || '',
      nationalIdentity: customer?.nationalIdentity || '',
      postalCode: customer?.postalCode || '',
      streetName: customer?.streetName || '',
      streetNumber: customer?.streetNumber || '',
      complement: customer?.complement || '',
      landmark: customer?.landmark || '',
      neighborhood: customer?.neighborhood || '',
      city: customer?.city || '',
      phone: customer?.phone || '',
      email: customer?.email || '',
      priceTableId: customer?.priceTableId || '',
      note: customer?.note || '',
      outputNote: customer?.outputNote || '',
    },
  });

  const handlePostalCodeError = (error?: boolean) => {
    const errorMessage = error
      ? 'CEP inválido'
      : 'Não foi possível buscar o cep';

    enqueueSnackbar(errorMessage, {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom',
      },
    });
  };

  const handleViaCEPSuccess = () => {
    const response = viaCepQuery.data as any;

    if (response.data.erro) {
      handlePostalCodeError(response.data.erro);
    }

    setValue('streetName', response.data.logradouro);
    setValue('complement', response.data.complemento);
    setValue('neighborhood', response.data.bairro);
    setValue('city', response.data.localidade);
  };

  const customerType = watch('type');
  const businessName = watch('businessName');
  const phone = watch('phone');
  const postalCode = watch('postalCode');
  const streetName = watch('streetName');
  const complement = watch('complement');
  const neighborhood = watch('neighborhood');
  const city = watch('city');

  const viaCepQuery = useViaCepAPI({
    enabled:
      !!postalCode &&
      !postalCode?.includes('_') &&
      postalCode.length === 9 &&
      !!dirtyFields.postalCode,
    postalCode,
  });

  useEffect(() => {
    if (viaCepQuery.data) {
      handleViaCEPSuccess();
    }
  }, [viaCepQuery.data]);

  const handleNameBlur = (currentName: string) => {
    const currentBusinessName = getValues('businessName');

    if (currentBusinessName) return;

    setValue('businessName', currentName);
  };

  return (
    <>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Box component="form">
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <Typography variant="body1" fontWeight="bold">
              Dados do Cliente
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack spacing={3}>
            <FormControl error={!!errors.type}>
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={CUSTOMER_TYPES}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    disabled={isLoading}
                    value={
                      value
                        ? CUSTOMER_TYPES.find((type) => type.id === value)
                        : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    onKeyDown={(e) => handleKeyDown(e, 0, 'type')}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        autoFocus
                        label="Tipo"
                        error={!!errors.type}
                        inputRef={(el) => {
                          inputRefs.current[0] = el;
                        }}
                      />
                    )}
                  />
                )}
              />
              {!!errors.type && (
                <FormHelperText>{errors.type.message}</FormHelperText>
              )}
            </FormControl>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <TextField
                type="text"
                label={customerType === 'PF' ? 'Nome' : 'Razão Social'}
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
                disabled={isLoading}
                {...register('name', {
                  onBlur: ({ target }) => handleNameBlur(target.value),
                })}
                inputRef={(el) => {
                  inputRefs.current[1] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 1, 'name')}
              />
              <TextField
                type="text"
                label={customerType === 'PF' ? 'Apelido' : 'Nome Fantasia'}
                fullWidth
                error={!!errors.businessName}
                helperText={errors.businessName?.message}
                disabled={isLoading}
                InputLabelProps={{ shrink: !!businessName }}
                {...register('businessName')}
                inputRef={(el) => {
                  inputRefs.current[2] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 2, 'businessName')}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <MaskedInput
                mask={customerType === 'PF' ? CPF_MASK : CNPJ_MASK}
                disabled={isLoading}
                textFieldProps={{
                  type: 'text',
                  label: customerType === 'PF' ? 'CPF' : 'CNPJ',
                  fullWidth: true,
                  error: !!errors.employerNumber,
                  helperText: errors.employerNumber?.message,
                  ...register('employerNumber'),
                  inputRef: (el) => {
                    inputRefs.current[3] = el;
                  },
                  onKeyDown: (e) => handleKeyDown(e, 3, 'employerNumber'),
                }}
                {...register('employerNumber')}
              />
              <TextField
                type="text"
                label={customerType === 'PF' ? 'RG' : 'Inscrição Estadual'}
                fullWidth
                error={!!errors.nationalIdentity}
                helperText={errors.nationalIdentity?.message}
                disabled={isLoading}
                {...register('nationalIdentity')}
                inputRef={(el) => {
                  inputRefs.current[4] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 4, 'nationalIdentity')}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <MaskedInput
                mask={
                  phone?.includes('____-____')
                    ? UNIDENTIFIED_PHONE_MASK
                    : (customer?.id && phone?.length === 11) ||
                      phone?.charAt(5) === '9'
                    ? CELLPHONE_MASK
                    : PHONE_MASK
                }
                disabled={isLoading}
                textFieldProps={{
                  type: 'text',
                  label: 'Telefone',
                  fullWidth: true,
                  error: !!errors.phone,
                  helperText: errors.phone?.message,
                  ...register('phone'),
                  inputRef: (el) => {
                    inputRefs.current[5] = el;
                  },
                  onKeyDown: (e) => handleKeyDown(e, 5, 'phone'),
                }}
                {...register('phone')}
              />
              <TextField
                type="email"
                label="E-mail"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
                disabled={isLoading}
                {...register('email')}
                inputRef={(el) => {
                  inputRefs.current[6] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 6, 'email')}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <TextField
                label="Observações"
                multiline
                maxRows={4}
                fullWidth
                disabled={isLoading}
                {...register('note')}
                inputRef={(el) => {
                  inputRefs.current[7] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 7, 'note', true)}
              />
              <TextField
                label="Observações de Venda"
                multiline
                maxRows={4}
                fullWidth
                disabled={isLoading}
                {...register('outputNote')}
                inputRef={(el) => {
                  inputRefs.current[8] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, 8, 'outputNote', true)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" my={3}>
            <Typography variant="body1" fontWeight="bold">
              Endereço
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <MaskedInput
              mask={CEP_MASK}
              disabled={isLoading}
              textFieldProps={{
                type: 'text',
                label: 'CEP',
                error: !!errors.postalCode,
                helperText: errors.postalCode?.message,
                inputRef: (el) => {
                  inputRefs.current[9] = el;
                },
                onKeyDown: (e) => handleKeyDown(e, 9, 'postalCode'),
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {viaCepQuery.isLoading &&
                      viaCepQuery.fetchStatus !== 'idle' ? (
                        <CircularProgress size={16} />
                      ) : (
                        <Place />
                      )}
                    </InputAdornment>
                  ),
                },
                ...register('postalCode'),
              }}
              {...register('postalCode')}
            />
            <TextField
              type="text"
              label="Endereço"
              fullWidth
              error={!!errors.streetName}
              helperText={errors.streetName?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!streetName }}
              {...register('streetName')}
              inputRef={(el) => {
                inputRefs.current[10] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 10, 'streetName')}
            />
            <TextField
              type="text"
              label="Número"
              error={!!errors.streetNumber}
              helperText={errors.streetNumber?.message}
              disabled={isLoading}
              {...register('streetNumber')}
              inputRef={(el) => {
                inputRefs.current[11] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 11, 'streetNumber')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} my={3}>
            <TextField
              type="text"
              label="Complemento"
              fullWidth
              error={!!errors.complement}
              helperText={errors.complement?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!complement }}
              {...register('complement')}
              inputRef={(el) => {
                inputRefs.current[12] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 12, 'complement')}
            />
            <TextField
              type="text"
              label="Ponto de Referência"
              fullWidth
              error={!!errors.landmark}
              helperText={errors.landmark?.message}
              disabled={isLoading}
              {...register('landmark')}
              inputRef={(el) => {
                inputRefs.current[13] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 13, 'landmark')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} my={3}>
            <TextField
              type="text"
              label="Bairro"
              fullWidth
              error={!!errors.neighborhood}
              helperText={errors.neighborhood?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!neighborhood }}
              {...register('neighborhood')}
              inputRef={(el) => {
                inputRefs.current[14] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 14, 'neighborhood')}
            />
            <TextField
              type="text"
              label="Cidade"
              fullWidth
              error={!!errors.city}
              helperText={errors.city?.message}
              disabled={isLoading}
              InputLabelProps={{ shrink: !!city }}
              {...register('city')}
              inputRef={(el) => {
                inputRefs.current[15] = el;
              }}
              onKeyDown={(e) => handleKeyDown(e, 15, 'city')}
            />
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center" my={3}>
            <Typography variant="body1" fontWeight="bold">
              Tabela de Preço
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>

          <Stack>
            <FormControl fullWidth error={!!errors?.priceTableId}>
              <Controller
                name="priceTableId"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={priceTables}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    disabled={isLoading}
                    value={
                      value
                        ? priceTables?.find(
                            (priceTable) => priceTable.id === value
                          )
                        : null
                    }
                    onChange={(_, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    onKeyDown={(e) => handleKeyDown(e, 16, 'priceTableId')}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Tabela de Preço"
                        error={!!errors.priceTableId}
                        inputRef={(el) => {
                          inputRefs.current[16] = el;
                        }}
                      />
                    )}
                  />
                )}
              />
              {!!errors?.priceTableId && (
                <FormHelperText>{errors.priceTableId.message}</FormHelperText>
              )}
            </FormControl>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          ref={submitButtonRef}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
