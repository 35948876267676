import { BackendPriceTableProduct } from '@/@types/price-table';

export const transformPriceTableProductsToFrontend = (
  products: BackendPriceTableProduct[]
) => {
  return products.map((product) => ({
    priceTableId: product.price_table_id,
    productId: product.product_id,
    productUnitId: product.product_unit_id,
    productPrice: product.product_price,
    product: product.product,
    unit: product.unit,
    handleEditProduct: () => {},
    handleRemoveSelectedProduct: () => {},
  }));
};
