import { ReactNode } from 'react';

interface IResponseDataHandleProps {
  isLoading: boolean;
  isError: boolean;
  loadingComponent: ReactNode;
  errorComponent: ReactNode;
  totalItems: number | undefined;
  emptyComponent: ReactNode;
  children: ReactNode;
}

export function ResponseDataHandle({
  isLoading,
  isError,
  loadingComponent,
  errorComponent,
  totalItems,
  emptyComponent,
  children,
}: IResponseDataHandleProps) {
  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (isError) {
    return <>{errorComponent}</>;
  }

  if (!totalItems) {
    return <>{emptyComponent}</>;
  }

  return <>{children}</>;
}
