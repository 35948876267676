import {
  Dashboard,
  ManageAccounts,
  People,
  Store,
  Settings,
  Business,
  Groups,
  SquareFoot,
  Category,
  Inventory,
  Sell,
  Tune,
  ShoppingCart,
  ShoppingBasket,
  Payments,
  Payment,
  ShoppingBasketOutlined,
  ShoppingCartOutlined,
  SupportAgent,
  Bookmarks,
} from '@mui/icons-material';

interface ISidebarIconsProps {
  sidebarName: string;
}

export function SidebarIcons({ sidebarName }: ISidebarIconsProps) {
  if (sidebarName.includes('Painel Administrativo')) return <Dashboard />;
  if (sidebarName.includes('Colaboradores')) return <Groups />;
  if (sidebarName.includes('Perfis')) return <ManageAccounts />;
  if (sidebarName.includes('Usuários')) return <People />;
  if (sidebarName.includes('Unidades')) return <SquareFoot />;
  if (sidebarName.includes('Categorias')) return <Category />;
  if (sidebarName.includes('Produtos')) return <Inventory />;
  if (sidebarName.includes('Tabelas de Preço')) return <Sell />;
  if (sidebarName.includes('Tipos de Operação')) return <Tune />;
  if (sidebarName.includes('Tipos de Documento')) return <Payment />;
  if (sidebarName.includes('Condições de Pagamento')) return <Payments />;
  if (sidebarName.includes('Situações')) return <Bookmarks />;
  if (sidebarName.includes('Clientes')) return <Store />;
  if (sidebarName.includes('Fornecedores')) return <Business />;
  if (sidebarName.includes('Outras Entradas'))
    return <ShoppingBasketOutlined />;
  if (sidebarName.includes('Outras Saídas')) return <ShoppingCartOutlined />;
  if (sidebarName.includes('Compras')) return <ShoppingBasket />;
  if (sidebarName.includes('Vendas')) return <ShoppingCart />;
  if (sidebarName.includes('Televendas')) return <SupportAgent />;
  if (sidebarName.includes('Configurações')) return <Settings />;

  return null;
}
