import { TPaymentTermInstallment } from '@/@types/payment-term';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { TableActions } from '@/components/common/Table/TableActions';

export const PaymentTermInstallmentsTableColumns: ColumnDefinitionType<TPaymentTermInstallment>[] =
  [
    {
      key: 'id',
      header: 'ID',
      sort: '',
    },
    {
      key: 'documentType',
      header: 'Tipo de Documento',
      sort: '',
      render: (paymentTerm) => paymentTerm.documentType?.name || '',
    },
    {
      key: 'daysAmount',
      header: 'Prazo (Dias)',
      sort: '',
    },
    {
      key: 'id',
      header: 'Ações',
      sort: '',
      render: (paymentTerm) => (
        <TableActions
          id={paymentTerm.id}
          editButtonPermission="payment_terms_create"
          deleteButtonPermission="payment_terms_delete"
          restoreButtonPermission="payment_terms_restore"
          onEdit={() => paymentTerm.handleEditInstallment(paymentTerm)}
          onDelete={() =>
            paymentTerm.handleRemoveSelectedInstallment(paymentTerm.id)
          }
        />
      ),
    },
  ];
