import { Permission } from '@/@types/permission';
import { menuRoutes } from '../../routes/menu.routes';

type GetUserAvailableUserRoutesParams = {
  isAdmin: boolean;
  permissions: Permission[];
};

export function getAvailableUserRoutes({
  isAdmin,
  permissions,
}: GetUserAvailableUserRoutesParams) {
  if (isAdmin) return menuRoutes;

  if (!permissions) return [];

  const userRolePermissions = permissions.map((permission) => permission.name);

  return menuRoutes.filter((menuRoute) =>
    userRolePermissions.includes(menuRoute.permission)
  );
}
