import {
  Box,
  DialogContent,
  Stack,
  DialogActions,
  Skeleton,
  Grid,
} from '@mui/material';

export function CustomerFormPlaceholder() {
  return (
    <Box>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack>
          <Skeleton height={72} />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Skeleton width="100%" height={72} />
            <Skeleton width="100%" height={72} />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Skeleton width="100%" height={72} />
            <Skeleton width="100%" height={72} />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Skeleton width="100%" height={72} />
            <Skeleton width="100%" height={72} />
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <Skeleton width="100%" height={72} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton width="100%" height={72} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Skeleton width="100%" height={72} />
            </Grid>
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Skeleton width="100%" height={72} />
            <Skeleton width="100%" height={72} />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <Skeleton width="100%" height={72} />
            <Skeleton width="100%" height={72} />
          </Stack>
          <Skeleton height={72} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Skeleton width={100} height={56} />
        <Skeleton width={100} height={56} />
      </DialogActions>
    </Box>
  );
}
