import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  tabsClasses,
  TextField,
} from '@mui/material';
import * as zod from 'zod';
import { SyntheticEvent, useState } from 'react';
import { TabPanel } from '@/components/common/TabPanel';
import { Role } from '@/@types/roles';
import { Permission, PermissionsGrouped } from '@/@types/permission';

const roleFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome do perfil')
    .transform((name) => name.trim().toUpperCase()),
  permissions: zod.string().array().min(1, 'Informe as permissões'),
});

export type RoleFormData = zod.infer<typeof roleFormSchema>;

interface IRoleFormProps {
  role?: Role;
  permissions: PermissionsGrouped[];
  rawPermissions: Permission[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: RoleFormData) => void;
  onCancel: () => void;
}

export function RoleForm({
  role,
  permissions,
  rawPermissions,
  isLoading,
  onSubmit,
  onCancel,
}: IRoleFormProps) {
  const [currentTab, setCurrentTab] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<RoleFormData>({
    resolver: zodResolver(roleFormSchema),
    defaultValues: {
      name: role?.name || '',
      permissions:
        role?.permissions.map((rolePermission) => rolePermission.id) || [],
    },
  });

  const currentFormPermissions = getValues('permissions');

  const handleChangeTab = (event: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  const isPermissionActive = (permissionId: string) => {
    return currentFormPermissions?.some(
      (permission) => permission === permissionId
    );
  };

  const isAllPermissionsActive = () => {
    return rawPermissions?.every((permission) =>
      currentFormPermissions.includes(permission.id)
    );
  };

  const handleChangePermission = (permission: Permission, checked: boolean) => {
    if (checked) {
      setValue('permissions', [...currentFormPermissions, permission.id], {
        shouldValidate: true,
      });
    } else {
      setValue(
        'permissions',
        currentFormPermissions.filter(
          (currentPermission) => currentPermission !== permission.id
        ),
        {
          shouldValidate: true,
        }
      );
    }
  };

  const handleToggleAllPermissions = (checked: boolean) => {
    if (checked) {
      setValue(
        'permissions',
        rawPermissions.map((permission) => permission.id),
        {
          shouldValidate: true,
        }
      );
    } else {
      setValue('permissions', [], {
        shouldValidate: true,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            spacing={2}
          >
            <TextField
              type="text"
              label="Nome"
              autoFocus
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              disabled={isLoading}
              {...register('name')}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllPermissionsActive()}
                    onChange={({ target }) =>
                      handleToggleAllPermissions(target.checked)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Todas"
              />
            </FormGroup>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {permissions.map((permission) => (
                <Tab key={permission.id} label={permission.name} />
              ))}
            </Tabs>
          </Box>
          {permissions.map((permission, index) => (
            <TabPanel key={permission.id} value={currentTab} index={index}>
              <Grid
                container
                justifyContent={{ xs: 'start', md: 'center' }}
                spacing={1}
              >
                {permission.permissions.map((tabPermission) => (
                  <Grid key={tabPermission.id} item xs>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isPermissionActive(tabPermission.id)}
                            onChange={({ target }) =>
                              handleChangePermission(
                                tabPermission,
                                target.checked
                              )
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={tabPermission.description}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
              {!!errors.permissions && (
                <FormHelperText error={!!errors.permissions}>
                  {errors.permissions.message}
                </FormHelperText>
              )}
            </TabPanel>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
