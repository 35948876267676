import { Customer } from '@/@types/customer';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { Typography } from '@mui/material';
import { CustomersTableActions } from './CustomersTableActions';

export const CustomersTableColumns: ColumnDefinitionType<Customer>[] = [
  {
    key: 'number',
    header: 'Número',
    sort: 'number',
  },
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
    render: (obj) => (
      <>
        <Typography variant="body2">{obj.businessName}</Typography>
        <Typography variant="caption" color="GrayText" component="span">
          {obj.name}
        </Typography>
      </>
    ),
  },
  {
    key: 'employerNumber',
    header: 'CPF/CNPJ',
    sort: 'employer_number',
  },
  {
    key: 'phone',
    header: 'Telefone',
    sort: 'phone',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: CustomersTableActions,
  },
];
