import { PaymentTerm } from '@/@types/payment-term';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { PaymentTermsTableActions } from './PaymentTermsTableActions';

export const PaymentTermsTableColumns: ColumnDefinitionType<PaymentTerm>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: PaymentTermsTableActions,
  },
];
