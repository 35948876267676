import { Paper, Table as MuiTable, TableContainer } from '@mui/material';
import React from 'react';
import { ColumnDefinitionType } from './table.utils';
import { TableHeaders } from './TableHeaders';
import { ITablePaginationProps, TablePagination } from './TablePagination';
import { TableRows } from './TableRows';

interface ITableProps<T> {
  data: Array<T>;
  columns: ColumnDefinitionType<T>[];
  additionalData: object;
  sortBy?: keyof T;
  sort?: 'desc' | 'asc';
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  pagination?: ITablePaginationProps;
  maxHeight?: number;
  selected?: string[];
  selectedKey?: keyof T;
  // eslint-disable-next-line no-unused-vars
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (event: React.MouseEvent<unknown>, id: string) => void;
}

export function Table<T>({
  data,
  columns,
  additionalData,
  sortBy,
  sort,
  onSort,
  pagination,
  maxHeight = 570,
  selected,
  selectedKey,
  onSelectAll,
  onItemClick,
}: ITableProps<T>) {
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight, boxShadow: 'none' }}>
        <MuiTable size="small" stickyHeader>
          <TableHeaders
            columns={columns}
            sortBy={sortBy}
            sort={sort}
            onSort={onSort}
            rowCount={data.length}
            numSelected={selected?.length}
            onSelectAll={onSelectAll}
          />
          <TableRows
            data={data}
            columns={columns}
            additionalData={additionalData}
            selected={selected}
            selectedKey={selectedKey}
            onItemClick={onItemClick}
          />
        </MuiTable>
      </TableContainer>
      {pagination ? <TablePagination {...pagination} /> : null}
    </>
  );
}
