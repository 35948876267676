import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

type TMoneyInputProps = {
  value: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  addonBefore?: string;
  readOnly?: boolean;
};

type MoneyInputProps = TMoneyInputProps & TextFieldProps;

const DECIMAL_SIZE = 2;

const transformValueToMoney = (value: string) => {
  if (!value) return '0.00';

  const valueRemoved = value.replace(',', '');
  const parseValueRemoved =
    valueRemoved.length < 4 ? `000${valueRemoved}` : valueRemoved;

  const sizeSlice = parseValueRemoved.length - DECIMAL_SIZE;
  const moneyValue = [
    parseValueRemoved.slice(0, sizeSlice),
    '.',
    parseValueRemoved.slice(sizeSlice),
  ].join('');

  return moneyValue;
};

export function MoneyInput({
  value,
  onChange,
  onBlur,
  addonBefore = 'R$',
  readOnly,
  ...props
}: MoneyInputProps) {
  const [currentValue, setCurrentValue] = useState<string>(`${value}`);

  useEffect(() => {
    const valueString = `${value}`;

    if (Number.isNaN(value)) {
      setCurrentValue('0,00');
    }

    if (!/\D/.test(valueString.replace('.', ''))) {
      setCurrentValue(value.toFixed(DECIMAL_SIZE).toString().replace('.', ','));
    }
  }, [value]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = transformValueToMoney(event.target.value);

    onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue,
      },
    });
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!onBlur) return;

    const newValue = transformValueToMoney(event.target.value);

    onBlur({
      ...event,
      target: {
        ...event.target,
        value: newValue,
      },
    });
  };

  return (
    <TextField
      value={currentValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      sx={{
        '& .MuiInputBase-root': {
          '& input': {
            textAlign: 'right',
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{addonBefore}</InputAdornment>
        ),
        readOnly,
      }}
      {...props}
    />
  );
}
