import { PriceTable } from '@/@types/price-table';
import { api } from '@/services/apiClient';
import { useQuery } from '@tanstack/react-query';
import { transformPriceTableProductsToFrontend } from '../utils';

interface GetPriceTableProps {
  id?: string;
}

type GetPriceTableResponse = PriceTable;

export async function getPriceTable({
  id,
}: GetPriceTableProps): Promise<GetPriceTableResponse> {
  const { data } = await api.get(`/price_tables/${id}`);

  const transformedProducts = transformPriceTableProductsToFrontend(
    data.products
  );

  return {
    id: data.id,
    name: data.name,
    products: transformedProducts,
    createdAt: data.created_at,
    deletedAt: data.deleted_at,
  } as unknown as PriceTable;
}

export function useGetPriceTable({ id }: GetPriceTableProps) {
  return useQuery({
    queryKey: ['priceTable', id],
    queryFn: () => getPriceTable({ id }),
    enabled: !!id,
    staleTime: 1000 * 60 * 1, // 1 minute
  });
}
