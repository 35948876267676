import {
  Box,
  DialogContent,
  Stack,
  DialogActions,
  Skeleton,
} from '@mui/material';

export function DocumentTypeFormPlaceholder() {
  return (
    <Box>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <Skeleton height={72} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Skeleton width={100} height={56} />
        <Skeleton width={100} height={56} />
      </DialogActions>
    </Box>
  );
}
