/* eslint-disable no-nested-ternary */
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as zod from 'zod';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import { EmptyData, MoneyInput } from '@/components/common';
import { DatePicker } from '@mui/x-date-pickers';
import { convertNumberToMoney } from '@/utils';
import { Entry, EntryType } from '@/@types/entries';
import { Product } from '@/@types/product';
import { Vendor } from '@/@types/vendor';
import { Staff } from '@/@types/staff';
import { Settings } from '@/@types/settings';
import { Operation } from '@/@types/operation';
import { EntryProductsList } from '../EntryProductsList';

const entryFormSchema = zod.object({
  issueDate: zod.date({
    required_error: 'Informe a data de emissão',
    invalid_type_error: 'Data inválida',
  }),
  entryDate: zod.date({
    required_error: 'Informe a data de emissão',
    invalid_type_error: 'Data inválida',
  }),
  vendorId: zod.string().min(1, 'Informe o fornecedor'),
  staffId: zod.string().min(1, 'Informe o colaborador'),
  operationId: zod.string().min(1, 'Informe o tipo de operação'),
  invoiceNumber: zod.string(),
  invoiceKey: zod.string(),
  note: zod.string(),
  products: zod
    .array(
      zod.object({
        id: zod.coerce.number(),
        productId: zod.string(),
        productUnitId: zod.string(),
        productQuantity: zod.coerce.number().min(1, 'Quantidade inválida'),
        productUnitQuantity: zod.coerce.number().min(1, 'Quantidade inválida'),
        priceGross: zod.coerce
          .number()
          .refine((currentPrice) => currentPrice > 0, 'Preço inválido'),
        subtotalProduct: zod.coerce.number(),
        unitaryValue: zod.coerce.number(),
        discount: zod.coerce
          .number()
          .refine(
            (currentDiscount) => currentDiscount >= 0,
            'Desconto inválido'
          ),
        entryProduct: zod.object({
          name: zod.string(),
          buyPrice: zod.coerce.number(),
        }),
        entryUnit: zod.object({ abbreviation: zod.string() }),
        handleEditProduct: zod.function(),
        handleRemoveSelectedProduct: zod.function(),
        isEntryFinished: zod.boolean(),
      })
    )
    .min(1, 'Informe o produto'),
});

export type EntryFormData = zod.infer<typeof entryFormSchema>;

type SelectedProduct = {
  id: number;
  productId: string;
  productUnitId: string;
  productQuantity: number;
  productUnitQuantity: number;
  priceGross: number;
  discount: number;
  subtotalProduct: number;
  unitaryValue: number;
  entryProduct: { name: string; buyPrice: number };
  entryUnit: { abbreviation: string };
  // eslint-disable-next-line no-unused-vars
  handleEditProduct: (product: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveSelectedProduct: (productId: any) => void;
  isEntryFinished: boolean;
};

interface IEntryFormProps {
  entryType: EntryType;
  entry?: Entry;
  products: Product[];
  operations: Operation[];
  vendors: Vendor[];
  staffs: Staff[];
  isLoading: boolean;
  settings?: Settings;
  defaultStaffId?: string;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: EntryFormData) => void;
  onCancel: () => void;
}

export function EntryForm({
  entryType,
  entry,
  products,
  operations,
  vendors,
  staffs,
  isLoading,
  settings,
  defaultStaffId,
  onSubmit,
  onCancel,
}: IEntryFormProps) {
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    SelectedProduct | undefined
  >();

  const theme = useTheme();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<EntryFormData>({
    resolver: zodResolver(entryFormSchema),
    defaultValues: {
      issueDate: entry?.issueDate ? new Date(entry?.issueDate) : new Date(),
      entryDate: entry?.entryDate ? new Date(entry?.entryDate) : new Date(),
      vendorId: entry
        ? entry?.vendorId
        : entryType === 'COMPRAS'
        ? settings?.purchaseVendorId || ''
        : settings?.otherEntryVendorId || '',
      staffId: entry ? entry?.staffId : defaultStaffId || '',
      operationId: entry
        ? entry?.operationId
        : entryType === 'COMPRAS'
        ? settings?.purchaseOperationId || ''
        : settings?.otherEntryOperationId || '',
      invoiceNumber: entry?.invoiceNumber || '',
      invoiceKey: entry?.invoiceKey || '',
      note: entry?.note || '',
      products: entry?.products || [],
    },
  });

  const issueDate = watch('issueDate');
  const vendorId = watch('vendorId');
  const currentProducts = getValues('products');
  const isEntryFinished = !!entry?.isFinished || !!entry?.deletedAt;

  const getNextProductId = () => {
    const lastId = currentProducts.length;
    return lastId + 1;
  };

  const handleEditProduct = (productToEdit: any) => {
    setIsEditingProduct(true);
    setSelectedProduct(productToEdit);
  };

  const handleRemoveSelectedProduct = (productId: string) => {
    setValue(
      'products',
      [...currentProducts.filter((product) => product.productId !== productId)],
      {
        shouldValidate: true,
      }
    );
    setSelectedProduct(undefined);
    setIsEditingProduct(false);
  };

  const handleSelectProduct = (id: string | number) => {
    const findProduct = products.find((product) => product.id === id);

    if (findProduct) {
      setSelectedProduct({
        id: getNextProductId(),
        productId: findProduct.id,
        productUnitId: findProduct.unit.id,
        productUnitQuantity: findProduct.unit.quantity,
        productQuantity: 1,
        discount: 0,
        priceGross: findProduct.buyPrice,
        subtotalProduct: findProduct.buyPrice,
        unitaryValue: findProduct.buyPrice,
        entryProduct: {
          name: findProduct.name,
          buyPrice: findProduct.buyPrice,
        },
        entryUnit: {
          abbreviation: findProduct.unit.abbreviation,
        },
        handleEditProduct,
        handleRemoveSelectedProduct,
        isEntryFinished: !!entry?.isFinished,
      });
    }
  };

  const subtotalSelectedProduct = selectedProduct
    ? selectedProduct.productQuantity * selectedProduct.priceGross -
      selectedProduct.discount
    : 0;

  const unitaryValueSelectedProduct = subtotalSelectedProduct
    ? subtotalSelectedProduct / (selectedProduct?.productQuantity || 0)
    : 0;

  const handleChangeSelectedProductQuantity = (value: string) => {
    const inputQuantity = Number(value) || 1;

    setSelectedProduct({
      ...selectedProduct,
      productQuantity: inputQuantity,
      subtotalProduct: subtotalSelectedProduct,
    } as SelectedProduct);
  };

  const handleChangeSelectedProductPrice = (value: string) => {
    const inputPriceValue = Number(value) || 0;

    setSelectedProduct({
      ...selectedProduct,
      priceGross: inputPriceValue,
      subtotalProduct: subtotalSelectedProduct,
    } as SelectedProduct);
  };

  const handleChangeSelectedProductDiscount = (value: string) => {
    const inputDiscount = Number(value) || 0;

    setSelectedProduct({
      ...selectedProduct,
      discount: inputDiscount,
      subtotalProduct: subtotalSelectedProduct,
    } as SelectedProduct);
  };

  const handleAddSelectedProduct = () => {
    if (!selectedProduct) return;

    const productExists = currentProducts.find(
      (product) => product.productId === selectedProduct?.productId
    );

    if (productExists) {
      const updatedProducts = currentProducts.map((product) => {
        if (product.productId === productExists.productId) {
          return {
            ...selectedProduct,
            subtotalProduct: subtotalSelectedProduct,
            unitaryValue: unitaryValueSelectedProduct,
          };
        }

        return product;
      });

      setValue('products', [...updatedProducts], {
        shouldValidate: true,
      });
    } else {
      setValue(
        'products',
        [
          ...currentProducts,
          {
            ...selectedProduct,
            subtotalProduct: subtotalSelectedProduct,
            unitaryValue: unitaryValueSelectedProduct,
          },
        ],
        {
          shouldValidate: true,
        }
      );
    }

    setSelectedProduct(undefined);
    setIsEditingProduct(false);
  };

  const totalGross = currentProducts.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.priceGross * product.productQuantity);
  }, 0);

  const totalDiscount = currentProducts.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.discount);
  }, 0);

  const entryTotal = totalGross - totalDiscount;

  return (
    <>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Box component="form">
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <FormControl fullWidth error={!!errors?.operationId}>
                <Controller
                  name="operationId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={operations}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isEntryFinished}
                      value={
                        value
                          ? operations?.find(
                              (operation) => operation.id === value
                            )
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        onChange(newValue ? newValue.id : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Tipo de Operação"
                          error={!!errors.operationId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.operationId && (
                  <FormHelperText>{errors.operationId.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <Controller
                control={control}
                name="issueDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label="Data de Emissão"
                    maxDate={new Date()}
                    readOnly={isEntryFinished}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur,
                        name,
                        error: !!fieldState.error,
                        helperText: errors?.issueDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="entryDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label="Data de Entrada"
                    minDate={issueDate}
                    readOnly={isEntryFinished}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur,
                        name,
                        error: !!fieldState.error,
                        helperText: errors?.entryDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <FormControl fullWidth error={!!errors?.vendorId}>
                <Controller
                  name="vendorId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={vendors}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isEntryFinished}
                      value={
                        value
                          ? vendors?.find((vendor) => vendor.id === value)
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        onChange(newValue ? newValue.id : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Fornecedor"
                          error={!!errors.vendorId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.vendorId && (
                  <FormHelperText>{errors.vendorId.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={!!errors?.staffId}>
                <Controller
                  name="staffId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={staffs}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isEntryFinished}
                      value={
                        value
                          ? staffs?.find((staff) => staff.id === value)
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        onChange(newValue ? newValue.id : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Colaborador"
                          error={!!errors.staffId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.staffId && (
                  <FormHelperText>{errors.staffId.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <TextField
              label="Observações"
              multiline
              disabled={isEntryFinished}
              maxRows={4}
              {...register('note')}
            />
            <Stack direction="row" spacing={1} alignItems="center" mb={2}>
              <Typography variant="body1" fontWeight="bold">
                Produtos
              </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
            <Box>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl fullWidth error={!!errors.products}>
                  <Autocomplete
                    options={products}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    readOnly={isEntryFinished}
                    value={
                      selectedProduct
                        ? products?.find(
                            (product) =>
                              product.id === selectedProduct.productId
                          )
                        : null
                    }
                    onChange={(event: any, newValue) =>
                      handleSelectProduct(newValue ? newValue.id : '')
                    }
                    disabled={isEntryFinished || isEditingProduct || !vendorId}
                    sx={{
                      '.MuiInputBase-root': {
                        fontSize: 14,
                        height: '3.5rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Produto"
                        error={!!errors.products}
                      />
                    )}
                  />
                </FormControl>
                <TextField
                  type="number"
                  label="Quantidade"
                  sx={{ width: { xs: '100%', sm: '20rem' } }}
                  value={
                    selectedProduct?.productQuantity
                      ? Number(selectedProduct?.productQuantity)
                      : 0
                  }
                  onChange={({ target }) =>
                    handleChangeSelectedProductQuantity(target.value)
                  }
                  inputProps={{
                    style: {
                      fontSize: 14,
                      height: '1.438rem',
                    },
                  }}
                  disabled={!selectedProduct?.productId || isLoading}
                />
                <MoneyInput
                  label="Preço"
                  fullWidth
                  sx={{ width: { xs: '100%', sm: '20rem' } }}
                  value={
                    selectedProduct?.priceGross
                      ? Number(selectedProduct?.priceGross)
                      : 0
                  }
                  onChange={({ target }) =>
                    handleChangeSelectedProductPrice(target.value)
                  }
                  inputProps={{
                    style: {
                      fontSize: 14,
                      height: '1.438rem',
                    },
                  }}
                  error={selectedProduct && !selectedProduct?.priceGross}
                  disabled={!selectedProduct?.productId || isLoading}
                />
                <MoneyInput
                  label="Desconto"
                  sx={{ width: { xs: '100%', sm: '20rem' } }}
                  value={
                    selectedProduct?.discount
                      ? Number(selectedProduct?.discount)
                      : 0
                  }
                  onChange={({ target }) =>
                    handleChangeSelectedProductDiscount(target.value)
                  }
                  inputProps={{
                    style: {
                      fontSize: 14,
                      height: '1.438rem',
                    },
                  }}
                  disabled={!selectedProduct?.productId || isLoading}
                />
                <MoneyInput
                  label="Subtotal"
                  fullWidth
                  sx={{ width: { xs: '100%', sm: '20rem' } }}
                  value={subtotalSelectedProduct}
                  onChange={() => {}}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      height: '1.438rem',
                    },
                  }}
                  readOnly
                />
                <Tooltip title="Adicionar">
                  <Box display="flex" alignItems="center">
                    <IconButton
                      color="primary"
                      size="large"
                      onClick={handleAddSelectedProduct}
                      disabled={
                        !selectedProduct ||
                        selectedProduct?.discount < 0 ||
                        selectedProduct?.priceGross <= 0
                      }
                    >
                      <Add />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Stack>
              {!!errors?.products && (
                <Typography
                  variant="caption"
                  ml="0.875rem"
                  sx={{ color: (props) => props.palette.error.main }}
                >
                  {errors.products.message}
                </Typography>
              )}
            </Box>
            <Box
              border={`1px solid ${theme.palette.grey[400]}`}
              borderRadius={1}
              bgcolor={theme.palette.grey[200]}
            >
              {currentProducts?.length ? (
                <EntryProductsList
                  products={currentProducts}
                  actions={{
                    handleEditProduct,
                    handleRemoveSelectedProduct,
                    isEntryFinished,
                  }}
                />
              ) : (
                <EmptyData sx={{ height: '10rem' }} />
              )}
            </Box>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 3 }}
              justifyContent="center"
              p={1}
              borderRadius={1}
              border={1}
              borderColor={theme.palette.grey[300]}
              sx={{ backgroundColor: theme.palette.grey[100] }}
            >
              <Stack
                direction="row"
                spacing={{ xs: 0, sm: 0.5 }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
              >
                <Typography
                  component="span"
                  width={{ xs: '40%', sm: 'auto' }}
                  textAlign="right"
                >
                  Total Bruto:
                </Typography>
                <Typography
                  component="span"
                  fontWeight="bold"
                  width={{ xs: '40%', sm: 'auto' }}
                >
                  {convertNumberToMoney(totalGross)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={{ xs: 0, sm: 0.5 }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
              >
                <Typography
                  component="span"
                  width={{ xs: '40%', sm: 'auto' }}
                  textAlign="right"
                >
                  Desconto:
                </Typography>
                <Typography
                  component="span"
                  fontWeight="bold"
                  width={{ xs: '40%', sm: 'auto' }}
                >
                  {convertNumberToMoney(totalDiscount)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={{ xs: 0, sm: 0.5 }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
              >
                <Typography
                  component="span"
                  width={{ xs: '40%', sm: 'auto' }}
                  textAlign="right"
                >
                  Total:
                </Typography>
                <Typography
                  component="span"
                  fontWeight="bold"
                  width={{ xs: '40%', sm: 'auto' }}
                >
                  {convertNumberToMoney(entryTotal)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          {isEntryFinished ? 'Fechar' : 'Cancelar'}
        </Button>
        <Button
          type="submit"
          disabled={isLoading || isEntryFinished}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
