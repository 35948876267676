import {
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

export function EntryViewModalPlaceholder() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogContent dividers sx={{ py: 3, px: 2 }}>
      <Stack spacing={3}>
        <Stack spacing={{ xs: 1, sm: 0.5 }}>
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <Typography variant="body1" fontWeight="bold">
              Informações
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack alignItems="center" spacing={0.5} direction="row">
            <Typography variant="body2">Número:</Typography>
            <Skeleton width={isMobile ? '100%' : '10%'} />
          </Stack>
          <Stack alignItems="center" spacing={0.5} direction="row">
            <Typography variant="body2">Data:</Typography>
            <Skeleton width={isMobile ? '100%' : '10%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Cliente:</Typography>
            <Skeleton width={isMobile ? '100%' : '30%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Colaborador:</Typography>
            <Skeleton width={isMobile ? '100%' : '20%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Tabela de Preço:</Typography>
            <Skeleton width={isMobile ? '100%' : '20%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Condição de Pagamento:</Typography>
            <Skeleton width={isMobile ? '100%' : '20%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Observações:</Typography>
            <Skeleton width={isMobile ? '100%' : '40%'} />
          </Stack>
          <Stack
            spacing={{ xs: 0, sm: 0.5 }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Typography variant="body2">Status:</Typography>
            <Skeleton width={isMobile ? '100%' : '5rem'} />
          </Stack>
        </Stack>
        <Stack spacing={{ xs: 1, sm: 0.5 }}>
          <Stack direction="row" spacing={1} alignItems="center" mb={1}>
            <Typography variant="body1" fontWeight="bold">
              Produtos
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Stack>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12} sm={4}>
              <Skeleton width={isMobile ? '100%' : '80%'} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton width={isMobile ? '100%' : '40%'} />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} pt={1}>
            <Skeleton width={isMobile ? '100%' : '26.67%'} />
          </Stack>
        </Stack>
      </Stack>
    </DialogContent>
  );
}
