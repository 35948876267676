import { Category } from '@/@types/category';
import { Product } from '@/@types/product';
import { Settings } from '@/@types/settings';
import { Unit } from '@/@types/unit';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { ProductForm, ProductFormData } from '../ProductForm';
import { ProductFormPlaceholder } from '../ProductForm/ProductFormPlaceholder';

interface IProductModalProps {
  product?: Product;
  units: Unit[];
  categories: Category[];
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  settings?: Settings;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: ProductFormData) => void;
}

export function ProductModal({
  product,
  units,
  categories,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isFetching,
  settings,
  isSubmitting,
}: IProductModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {product ? 'Alterar Produto' : 'Novo Produto'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <ProductFormPlaceholder />
      ) : (
        <ProductForm
          product={product}
          units={units}
          categories={categories}
          isLoading={isSubmitting}
          settings={settings}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
