import { Role } from '@/@types/roles';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { RolesTableActions } from './RolesTableActions';

export const RolesTableColumns: ColumnDefinitionType<Role>[] = [
  {
    key: 'name',
    header: 'Nome',
    sort: 'name',
  },
  {
    key: 'createdAt',
    header: 'Criado em',
    sort: 'created_at',
  },
  {
    key: 'id',
    header: 'Ações',
    render: RolesTableActions,
  },
];
