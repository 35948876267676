import React from 'react';
import { Table } from '@/components/common';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { Output } from '@/@types/outputs';

export interface IOutputsTableProps {
  data: Output[];
  columns: ColumnDefinitionType<Output>[];
  sort?: 'desc' | 'asc';
  sortBy?: keyof Output;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
  selected?: string[];
  selectedKey?: keyof Output;
  // eslint-disable-next-line no-unused-vars
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (event: React.MouseEvent<unknown>, id: string) => void;
}

export function OutputsTable({
  data,
  columns,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
  selected,
  selectedKey,
  onSelectAll,
  onItemClick,
}: IOutputsTableProps) {
  return (
    <Table
      data={data}
      columns={columns}
      additionalData={actions}
      sort={sort}
      sortBy={sortBy}
      onSort={onSort}
      pagination={pagination}
      selected={selected}
      onSelectAll={onSelectAll}
      selectedKey={selectedKey}
      onItemClick={onItemClick}
    />
  );
}
