import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import * as zod from 'zod';
import { Staff } from '@/@types/staff';

const staffFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome do colaborador')
    .transform((name) => name.trim().toUpperCase()),
});

export type StaffFormData = zod.infer<typeof staffFormSchema>;

interface IStaffFormProps {
  staff?: Staff;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: StaffFormData) => void;
  onCancel: () => void;
}

export function StaffForm({
  staff,
  isLoading,
  onSubmit,
  onCancel,
}: IStaffFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StaffFormData>({
    resolver: zodResolver(staffFormSchema),
    defaultValues: {
      name: staff?.name || '',
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Nome"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={isLoading}
            {...register('name')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
