import { Print } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { CustomMenu } from '../CustomMenu';

interface PrintMenuProps {
  posPrint?: boolean;
  posPrintSimple?: boolean;
  handlePOSPrint: () => void;
  handlePOSPrintSimple: () => void;
}

export function PrintMenu({
  posPrint,
  posPrintSimple,
  handlePOSPrint,
  handlePOSPrintSimple,
}: PrintMenuProps) {
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);

  const theme = useTheme();

  const menuItems = useMemo(() => {
    const posPrintMenuItem = {
      title: 'Imprimir (Bobina)',
      onClick: handlePOSPrint,
    };
    const posPrintSimplesMenuItem = {
      title: 'Imprimir (Bobina Simples)',
      onClick: handlePOSPrintSimple,
    };

    if (posPrint && posPrintSimple) {
      return [posPrintMenuItem, posPrintSimplesMenuItem];
    }

    if (posPrint) {
      return [posPrintMenuItem];
    }

    if (posPrintSimple) {
      return [posPrintSimplesMenuItem];
    }

    return [];
  }, [handlePOSPrint, handlePOSPrintSimple, posPrint, posPrintSimple]);

  return (
    <>
      <Tooltip title="Imprimir">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            return setMenuElement(event.currentTarget);
          }}
          size="small"
          sx={{
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          <Print />
        </IconButton>
      </Tooltip>
      <CustomMenu
        anchorEl={menuElement}
        onClose={() => setMenuElement(null)}
        menuItems={menuItems}
      />
    </>
  );
}
