import { Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  Stack,
} from '@mui/material';
import { Modal } from '../Modal';

interface IConfirmModalProps {
  isOpen: boolean;
  isLoading: boolean;
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmModal({
  isOpen,
  isLoading,
  onClose,
  title,
  message = 'Você tem certeza que deseja realizar está ação?',
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Confirmar',
  onConfirm,
}: IConfirmModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      {isLoading ? <LinearProgress /> : null}
      <DialogContent sx={{ p: 2 }}>
        <Stack alignItems="center" justifyContent="center">
          <Box fontSize="3.5rem">
            <Warning fontSize="inherit" color="warning" />
          </Box>
          {title ? (
            <DialogContentText variant="h5" fontWeight="bold" mb={1}>
              {title}
            </DialogContentText>
          ) : null}
          <DialogContentText>{message}</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
        <Button onClick={onClose} disabled={isLoading} autoFocus>
          {cancelButtonText}
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={isLoading}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Modal>
  );
}
