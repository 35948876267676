import { Dialog, DialogProps } from '@mui/material';
import { ReactNode } from 'react';

export interface IModalProps extends DialogProps {
  onClose: () => void;
  closeOnBackdropClick?: boolean;
  closeOnEscapeKeyDown?: boolean;
  children: ReactNode;
}

export function Modal({
  open,
  onClose,
  closeOnBackdropClick = true,
  closeOnEscapeKeyDown = true,
  children,
  ...props
}: IModalProps) {
  const handleClose = (
    event: object,
    reason: 'escapeKeyDown' | 'backdropClick'
  ) => {
    if (reason === 'backdropClick' && closeOnBackdropClick && onClose) {
      onClose();
    } else if (reason === 'escapeKeyDown' && closeOnEscapeKeyDown && onClose) {
      onClose();
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" {...props}>
      {children}
    </Dialog>
  );
}
