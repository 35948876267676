import { IconButton, Stack, Tooltip } from '@mui/material';
import { theme } from '@/styles/theme';
import { Delete, Edit, Restore } from '@mui/icons-material';
import { Can } from '../../Can';

interface ITableActionsProps {
  id: string | number;
  deletedAt?: string;
  editButtonPermission: string;
  deleteButtonPermission: string;
  restoreButtonPermission?: string;
  shouldDisableActions?: boolean;
  // eslint-disable-next-line no-unused-vars
  onEdit: (id: string | number) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete: (id: string | number) => void;
  // eslint-disable-next-line no-unused-vars
  onRestore?: (id: string | number) => void;
  // eslint-disable-next-line no-unused-vars
  onEditMouseEnter?: (id: string | number) => void;
}

export function TableActions({
  id,
  deletedAt,
  editButtonPermission,
  deleteButtonPermission,
  restoreButtonPermission,
  shouldDisableActions,
  onEdit,
  onDelete,
  onRestore,
  onEditMouseEnter,
}: ITableActionsProps) {
  const handleEditMouseEnter = () => {
    if (onEditMouseEnter) onEditMouseEnter(id);
  };

  const handleRestore = () => {
    if (onRestore) onRestore(id);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={[editButtonPermission]}>
        <Tooltip title="Alterar">
          <span>
            <IconButton
              onMouseEnter={handleEditMouseEnter}
              onClick={() => onEdit(id)}
              size="small"
              disabled={shouldDisableActions}
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Can>
      {deletedAt && restoreButtonPermission ? (
        <Can permissions={[restoreButtonPermission]}>
          <Tooltip title="Restaurar">
            <span>
              <IconButton
                onClick={handleRestore}
                size="small"
                disabled={shouldDisableActions}
                sx={{
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Restore fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={[deleteButtonPermission]}>
          <Tooltip title="Excluir">
            <span>
              <IconButton
                onClick={() => onDelete(id)}
                size="small"
                disabled={shouldDisableActions}
                sx={{
                  '&:hover': {
                    color: theme.palette.error.main,
                  },
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
