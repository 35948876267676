import InputMask from 'react-input-mask';
import { TextField, TextFieldProps } from '@mui/material';
import { IMaskProps } from './MaskedInput.utils';

interface IMaskedInputProps {
  mask: IMaskProps;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
}

export function MaskedInput({
  mask,
  disabled,
  textFieldProps,
  ...props
}: IMaskedInputProps) {
  return (
    <InputMask mask={mask} disabled={disabled} {...props}>
      <TextField {...textFieldProps} />
    </InputMask>
  );
}
