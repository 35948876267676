import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { SidebarDrawerProvider } from '@/contexts/SidebarDrawerContext';
import Router from 'next/router';
import { Header } from '../Header';
import { DrawerHeader, Sidebar } from '../Sidebar';
import { Loader } from '../Loader';

export function AppLayout({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });

    Router.events.on('routeChangeComplete', () => {
      setIsLoading(false);
    });

    Router.events.on('routeChangeError', () => {
      setIsLoading(false);
    });
  }, []);

  return (
    <SidebarDrawerProvider>
      <Header />
      <Sidebar />
      {isLoading ? <Loader /> : null}
      <Box component="main" sx={{ flexGrow: 1, py: 3 }}>
        <DrawerHeader />
        <Container maxWidth="lg">{children}</Container>
      </Box>
    </SidebarDrawerProvider>
  );
}
