/* eslint-disable no-nested-ternary */
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as zod from 'zod';
import { Add } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { EmptyData, MoneyInput } from '@/components/common';
import { DatePicker } from '@mui/x-date-pickers';
import { useGetPriceTable } from '@/modules/price_tables/hooks';
import { useSnackbar } from 'notistack';
import { addDays } from 'date-fns';
import { CUSTOM_PAYMENT_TERM } from '@/modules/payment_terms/utils';
import { Output, OutputType } from '@/@types/outputs';
import { Product } from '@/@types/product';
import { Operation } from '@/@types/operation';
import { Customer } from '@/@types/customer';
import { Staff } from '@/@types/staff';
import { PriceTable } from '@/@types/price-table';
import { PaymentTerm } from '@/@types/payment-term';
import { TDocumentType } from '@/@types/document-type';
import { Settings } from '@/@types/settings';
import { OutputTotal } from '../OutputTotal';
import { OutputInstallmentsList } from '../OutputInstallmentsList';
import { OutputProductsList } from '../OutputProductsList';

const outputFormSchema = zod
  .object({
    issueDate: zod.date({
      required_error: 'Informe a data de emissão',
      invalid_type_error: 'Data inválida',
    }),
    outputDate: zod.date({
      required_error: 'Informe a data de emissão',
      invalid_type_error: 'Data inválida',
    }),
    customerId: zod.string().min(1, 'Informe o cliente'),
    staffId: zod.string().min(1, 'Informe o colaborador'),
    operationId: zod.string().min(1, 'Informe o tipo de operação'),
    operation: zod
      .object({
        generatesInstallment: zod.boolean(),
      })
      .nullable(),
    paymentTermId: zod.string().nullable(),
    situationId: zod.string().nullable(),
    invoiceNumber: zod.string(),
    invoiceKey: zod.string(),
    note: zod.string(),
    priceTableId: zod.string(),
    products: zod
      .array(
        zod.object({
          id: zod.coerce.number(),
          productId: zod.string(),
          productUnitId: zod.string(),
          productQuantity: zod.coerce.number().min(1, 'Quantidade inválida'),
          productUnitQuantity: zod.coerce
            .number()
            .min(1, 'Quantidade inválida'),
          priceGross: zod.coerce
            .number()
            .refine((currentPrice) => currentPrice > 0, 'Preço inválido'),
          subtotalProduct: zod.coerce.number(),
          unitaryValue: zod.coerce.number(),
          discount: zod.coerce
            .number()
            .refine(
              (currentDiscount) => currentDiscount >= 0,
              'Desconto inválido'
            ),
          outputProduct: zod.object({
            name: zod.string(),
            salePrice: zod.coerce.number(),
          }),
          outputUnit: zod.object({ abbreviation: zod.string() }),
          handleEditProduct: zod.function(),
          handleRemoveSelectedProduct: zod.function(),
          isOutputFinished: zod.boolean(),
        })
      )
      .min(1, 'Informe o produto'),
    installments: zod.array(
      zod.object({
        id: zod.coerce.number(),
        documentTypeId: zod.string(),
        dueDate: zod.date({
          required_error: 'Informe a data de vencimento',
          invalid_type_error: 'Data inválida',
        }),
        amount: zod.coerce.number(),
        documentType: zod.object({
          name: zod.string(),
        }),
        handleEditInstallment: zod.function(),
        handleRemoveSelectedInstallment: zod.function(),
        isOutputFinished: zod.boolean(),
      })
    ),
  })
  .refine(
    (value) => {
      if (value.operation?.generatesInstallment) {
        const totalOutput = value.products.reduce((total, product) => {
          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (total += product.subtotalProduct);
        }, 0);

        const totalInstallments = value.installments.reduce(
          (total, installment) => {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            return (total += installment.amount);
          },
          0
        );

        return totalOutput === totalInstallments;
      }

      return true;
    },
    {
      message: 'Informe a parcela',
      path: ['installments'],
    }
  );

export type OutputFormData = zod.infer<typeof outputFormSchema>;

type SelectedProduct = {
  id: number;
  productId: string;
  productUnitId: string;
  productQuantity: number;
  productUnitQuantity: number;
  priceGross: number;
  discount: number;
  subtotalProduct: number;
  unitaryValue: number;
  outputProduct: { name: string; salePrice: number };
  outputUnit: { abbreviation: string };
  // eslint-disable-next-line no-unused-vars
  handleEditProduct: (product: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveSelectedProduct: (productId: any) => void;
  isOutputFinished: boolean;
};

type SelectedInstallment = {
  id: number;
  documentTypeId: string;
  dueDate: Date;
  amount: number;
  documentType: { name: string };
  // eslint-disable-next-line no-unused-vars
  handleEditInstallment: (installment: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveSelectedInstallment: (installmentId: any) => void;
  isOutputFinished: boolean;
};

interface IOutputFormProps {
  outputType: OutputType;
  output?: Output;
  products: Product[];
  operations: Operation[];
  customers: Customer[];
  staffs: Staff[];
  priceTables: PriceTable[];
  paymentTerms: PaymentTerm[];
  documentTypes: TDocumentType[];
  isLoading: boolean;
  settings?: Settings;
  defaultStaffId?: string;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: OutputFormData) => void;
  onCancel: () => void;
}

export function OutputForm({
  outputType,
  output,
  products,
  operations,
  customers,
  staffs,
  priceTables,
  paymentTerms,
  documentTypes,
  isLoading,
  settings,
  defaultStaffId,
  onSubmit,
  onCancel,
}: IOutputFormProps) {
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [isEditingInstallment, setIsEditingInstallment] = useState(false);
  const [isSelectedProductPriceDirty, setIsSelectedProductPriceDirty] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    SelectedProduct | undefined
  >();
  const [selectedInstallment, setSelectedInstallment] = useState<
    SelectedInstallment | undefined
  >();

  const originalSelectedProduct = useMemo(() => {
    return products.find(
      (product) => product.id === selectedProduct?.productId || 0
    );
  }, [products, selectedProduct?.productId]);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<OutputFormData>({
    resolver: zodResolver(outputFormSchema),
    defaultValues: {
      issueDate: output?.issueDate ? new Date(output?.issueDate) : new Date(),
      outputDate: output?.outputDate
        ? new Date(output?.outputDate)
        : new Date(),
      customerId: output
        ? output?.customerId
        : outputType === 'VENDAS'
        ? settings?.saleCustomerId || ''
        : settings?.otherOutputCustomerId || '',
      staffId: output ? output?.staffId : defaultStaffId || '',
      operationId: output
        ? output?.operationId
        : outputType === 'VENDAS'
        ? settings?.saleOperationId || ''
        : settings?.otherOutputOperationId || '',
      invoiceNumber: output?.invoiceNumber || '',
      invoiceKey: output?.invoiceKey || '',
      note: output?.note || '',
      priceTableId: output?.priceTableId || '',
      paymentTermId: output
        ? output?.paymentTermId
        : outputType === 'VENDAS'
        ? settings?.salePaymentTermId || ''
        : settings?.otherOutputPaymentTermId || '',
      situationId: output?.situationId || '',
      products: output?.products || [],
      installments: output?.installments || [],
    },
  });

  const issueDate = watch('issueDate');
  const customerId = watch('customerId');
  const note = watch('note');
  const currentPriceTableId = watch('priceTableId');
  const currentPaymentTermId = watch('paymentTermId');
  const currentProducts = watch('products');
  const currentInstallments = watch('installments');
  const currentOperationId = watch('operationId');
  const isOutputFinished = !!output?.isFinished || !!output?.deletedAt;

  const getPriceTableQuery = useGetPriceTable({ id: currentPriceTableId });
  const currentOperation = operations.find(
    (operation) => operation.id === currentOperationId
  );

  const currentPaymentTerm = useMemo(() => {
    if (!currentPaymentTermId) return null;

    return paymentTerms.find(
      (paymentTerm) => paymentTerm.id === currentPaymentTermId
    );
  }, [currentPaymentTermId, paymentTerms]);

  const customPaymentTerm = paymentTerms?.find(
    (paymentTerm) => paymentTerm.name.toUpperCase() === CUSTOM_PAYMENT_TERM
  );
  const isCustomPaymentTerm =
    currentPaymentTerm?.name?.toUpperCase() === CUSTOM_PAYMENT_TERM;

  const priceTableSelectedProduct = useMemo(() => {
    return getPriceTableQuery?.data?.products?.find(
      (product) => product.productId === selectedProduct?.productId
    );
  }, [getPriceTableQuery?.data?.products, selectedProduct?.productId]);

  const handleSelectOperation = (operation: Operation | null) => {
    if (operation && !operation?.generatesInstallment) {
      setValue('paymentTermId', '');
    }
    setValue('operation', operation);
  };

  const handleSelectCustomerPriceTable = (priceTableId: string | null) => {
    setValue('priceTableId', priceTableId || '');
  };

  const getNextProductId = () => {
    const lastId = currentProducts.length;
    return lastId + 1;
  };

  const handleEditProduct = (productToEdit: any) => {
    setIsEditingProduct(true);
    setSelectedProduct(productToEdit);
  };

  const handleRemoveSelectedProduct = (productId: string) => {
    setValue(
      'products',
      [...currentProducts.filter((product) => product.productId !== productId)],
      {
        shouldValidate: true,
      }
    );
    setSelectedProduct(undefined);
    setIsEditingProduct(false);
  };

  const handleSelectProduct = (id: string | number) => {
    const findProduct = products.find((product) => product.id === id);

    if (findProduct) {
      const currentPriceTableProduct = getPriceTableQuery?.data?.products?.find(
        (product) => product.productId === id
      );

      const productAlreadyAdded = currentProducts.find(
        (product) => product.productId === findProduct.id
      );

      setSelectedProduct({
        id: productAlreadyAdded?.id || getNextProductId(),
        productId: findProduct.id,
        productUnitId: findProduct.unit.id,
        productUnitQuantity: findProduct.unit.quantity,
        productQuantity: 1,
        discount: 0,
        priceGross:
          currentPriceTableProduct?.productPrice || findProduct.salePrice,
        subtotalProduct:
          currentPriceTableProduct?.productPrice || findProduct.salePrice,
        unitaryValue:
          currentPriceTableProduct?.productPrice || findProduct.salePrice,
        outputProduct: {
          name: findProduct.name,
          salePrice:
            currentPriceTableProduct?.productPrice || findProduct.salePrice,
        },
        outputUnit: {
          abbreviation: findProduct.unit.abbreviation,
        },
        handleEditProduct,
        handleRemoveSelectedProduct,
        isOutputFinished: !!output?.isFinished,
      });
    }
  };

  const subtotalSelectedProduct = selectedProduct
    ? selectedProduct.productQuantity * selectedProduct.priceGross -
      selectedProduct.discount
    : 0;

  const unitaryValueSelectedProduct = subtotalSelectedProduct
    ? subtotalSelectedProduct / (selectedProduct?.productQuantity || 0)
    : 0;

  const getCalculatedSelectedProductDiscount = (currentPrice = 0) => {
    const selectedProductPrice =
      currentPrice ||
      priceTableSelectedProduct?.productPrice ||
      selectedProduct?.priceGross ||
      0;
    const originalProductPrice =
      priceTableSelectedProduct?.productPrice ||
      originalSelectedProduct?.salePrice ||
      0;

    const discount =
      selectedProductPrice && selectedProductPrice < originalProductPrice
        ? (originalProductPrice - selectedProductPrice) *
          (selectedProduct?.productQuantity || 0)
        : 0;

    return discount;
  };

  const handleChangeSelectedProductQuantity = (value: string) => {
    const inputQuantity = Number(value) || 1;

    setSelectedProduct({
      ...selectedProduct,
      productQuantity: inputQuantity,
      subtotalProduct: subtotalSelectedProduct,
      discount: getCalculatedSelectedProductDiscount(
        unitaryValueSelectedProduct
      ),
    } as SelectedProduct);
  };

  const handleChangeSelectedProductPrice = (value: string) => {
    const inputPriceValue = Number(value) || 0;

    setSelectedProduct({
      ...selectedProduct,
      priceGross: inputPriceValue,
      subtotalProduct: subtotalSelectedProduct,
    } as SelectedProduct);

    setIsSelectedProductPriceDirty(true);
  };

  const handleBlurSelectedProductPrice = (value: string) => {
    if (!isSelectedProductPriceDirty) return;

    const inputPriceValue = Number(value) || 0;
    const originalProductPrice =
      priceTableSelectedProduct?.productPrice ||
      originalSelectedProduct?.salePrice ||
      0;

    setSelectedProduct({
      ...selectedProduct,
      priceGross:
        inputPriceValue < originalProductPrice
          ? originalProductPrice
          : inputPriceValue,
      subtotalProduct: subtotalSelectedProduct,
      discount: getCalculatedSelectedProductDiscount(inputPriceValue),
    } as SelectedProduct);

    setIsSelectedProductPriceDirty(false);
  };

  const handleChangeSelectedProductDiscount = (value: string) => {
    const inputDiscount = Number(value) || 0;

    setSelectedProduct({
      ...selectedProduct,
      discount: inputDiscount,
      subtotalProduct: subtotalSelectedProduct,
    } as SelectedProduct);
  };

  const handleAddSelectedProduct = () => {
    if (!selectedProduct) return;

    const productExists = currentProducts.find(
      (product) => product.productId === selectedProduct?.productId
    );

    if (productExists) {
      const updatedProducts = currentProducts.map((product) => {
        if (product.productId === productExists.productId) {
          return {
            ...selectedProduct,
            subtotalProduct: subtotalSelectedProduct,
            unitaryValue: unitaryValueSelectedProduct,
          };
        }

        return product;
      });

      setValue('products', [...updatedProducts], {
        shouldValidate: true,
      });
    } else {
      setValue(
        'products',
        [
          ...currentProducts,
          {
            ...selectedProduct,
            subtotalProduct: subtotalSelectedProduct,
            unitaryValue: unitaryValueSelectedProduct,
          },
        ],
        {
          shouldValidate: true,
        }
      );
    }

    setSelectedProduct(undefined);
    setIsEditingProduct(false);
  };

  const totalGross = currentProducts.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.priceGross * product.productQuantity);
  }, 0);

  const totalDiscount = currentProducts.reduce((total, product) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += product.discount);
  }, 0);

  const totalInstallments = currentInstallments.reduce((total, installment) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (total += installment.amount);
  }, 0);

  const outputTotal = totalGross - totalDiscount;

  const updateOutputInstallments = () => {
    if (isCustomPaymentTerm || !currentPaymentTerm || outputTotal <= 0) return;

    if (currentInstallments.length) {
      setValue(
        'installments',
        currentInstallments.map((installment) => ({
          ...installment,
          amount: outputTotal / currentInstallments.length,
        }))
      );
    } else {
      setValue(
        'installments',
        currentPaymentTerm.installments.map(
          (installment, index) =>
            ({
              id: index + 1,
              documentTypeId: installment.documentTypeId,
              documentType: installment.documentType,
              dueDate: addDays(new Date(), installment.daysAmount),
              // eslint-disable-next-line no-use-before-define
              handleEditInstallment,
              // eslint-disable-next-line no-use-before-define
              handleRemoveSelectedInstallment,
              isOutputFinished: !!output?.isFinished,
              amount: outputTotal / currentPaymentTerm.installments.length,
            } as SelectedInstallment)
        )
      );
    }
  };

  useEffect(() => {
    if (output) {
      const operation = operations.find(
        (item) => item.id === output.operationId
      ) as any;
      setValue('operation', operation);

      setValue(
        'installments',
        output?.installments
          .sort((a, b) => a.id - b.id)
          .map(
            (installment) =>
              ({
                ...installment,
                // eslint-disable-next-line no-use-before-define
                handleEditInstallment,
                // eslint-disable-next-line no-use-before-define
                handleRemoveSelectedInstallment,
                isOutputFinished: !!output?.isFinished,
              } as SelectedInstallment)
          ) || []
      );
    }

    if (!output && outputType === 'VENDAS' && settings?.saleOperationId) {
      const operation = operations.find(
        (item) => item.id === settings?.saleOperationId
      ) as any;
      setValue('operation', operation);

      if (!operation?.generatesInstallment) {
        setValue('paymentTermId', '');
      }
    } else if (
      !output &&
      outputType === 'OUTRAS SAÍDAS' &&
      settings?.otherOutputOperationId
    ) {
      const operation = operations.find(
        (item) => item.id === settings?.otherOutputOperationId
      ) as any;
      setValue('operation', operation);

      if (!operation?.generatesInstallment) {
        setValue('paymentTermId', '');
      }
    }

    if (!output && outputType === 'VENDAS' && settings?.saleCustomerId) {
      const findCustomer = customers.find(
        (customer) => customer.id === settings?.saleCustomerId
      );

      setValue('priceTableId', findCustomer?.priceTableId || '');
      setValue('note', findCustomer?.outputNote || '');
    } else if (
      !output &&
      outputType === 'OUTRAS SAÍDAS' &&
      settings?.otherOutputCustomerId
    ) {
      const findCustomer = customers.find(
        (customer) => customer.id === settings?.otherOutputCustomerId
      );

      setValue('priceTableId', findCustomer?.priceTableId || '');
      setValue('note', findCustomer?.outputNote || '');
    }
  }, []);

  useEffect(() => {
    updateOutputInstallments();
  }, [outputTotal]);

  const handleEditInstallment = (installmentToEdit: SelectedInstallment) => {
    setSelectedInstallment(installmentToEdit);
    setIsEditingInstallment(true);
  };

  const handleRemoveSelectedInstallment = (installmentId: number) => {
    setValue(
      'installments',
      currentInstallments.filter(
        (installment) => installment.id !== installmentId
      ),
      {
        shouldValidate: true,
      }
    );
  };

  const getNextInstallmentId = () => {
    const lastId = currentInstallments.length;
    return lastId + 1;
  };

  const handleSelectDocumentType = (documentType: TDocumentType) => {
    if (!documentType) return;

    const installmentAmount = outputTotal - totalInstallments;

    setSelectedInstallment({
      id: selectedInstallment?.id || getNextInstallmentId(),
      documentType: {
        name: documentType.name,
      },
      documentTypeId: documentType.id,
      dueDate: new Date(),
      amount: installmentAmount,
      handleEditInstallment,
      handleRemoveSelectedInstallment,
      isOutputFinished: !!output?.isFinished,
    });
  };

  const handleChangeSelectedInstallmentAmount = (value: string) => {
    const inputAmountValue = Number(value) || 0;

    setSelectedInstallment({
      ...selectedInstallment,
      amount: inputAmountValue,
    } as SelectedInstallment);
  };

  const handleAddSelectedInstallment = () => {
    if (!selectedInstallment) return;

    if (isEditingInstallment) {
      const newTotalInstallments = currentInstallments.reduce(
        (total, installment) => {
          if (installment.id === selectedInstallment.id) {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            return (total += selectedInstallment.amount);
          }

          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (total += installment.amount);
        },
        0
      );

      if (newTotalInstallments > outputTotal) {
        enqueueSnackbar('Valor da parcela inválido', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        });
        return;
      }
    } else {
      const newTotalInstallments =
        totalInstallments + selectedInstallment.amount;

      if (newTotalInstallments > outputTotal) {
        enqueueSnackbar('Valor da parcela inválido', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        });
        return;
      }
    }

    const installmentExists = currentInstallments.find(
      (installment) => installment.id === selectedInstallment?.id
    );

    if (installmentExists) {
      const updatedInstallments = currentInstallments.map((installment) => {
        if (installment.id === installmentExists.id) {
          return {
            ...selectedInstallment,
          };
        }

        return installment;
      });

      setValue('installments', [...updatedInstallments], {
        shouldValidate: true,
      });
    } else {
      setValue(
        'installments',
        [
          ...currentInstallments,
          {
            ...selectedInstallment,
          },
        ],
        {
          shouldValidate: true,
        }
      );
    }

    setValue('paymentTermId', customPaymentTerm?.id || '');
    setSelectedInstallment(undefined);
    setIsEditingInstallment(false);
  };

  const handleSelectPaymentTerm = (paymentTerm: PaymentTerm | null) => {
    if (paymentTerm?.installments?.length && outputTotal) {
      setValue(
        'installments',
        paymentTerm.installments.map(
          (installment, index) =>
            ({
              id: index + 1,
              documentTypeId: installment.documentTypeId,
              documentType: installment.documentType,
              dueDate: addDays(new Date(), installment.daysAmount),
              // eslint-disable-next-line no-use-before-define
              handleEditInstallment,
              // eslint-disable-next-line no-use-before-define
              handleRemoveSelectedInstallment,
              isOutputFinished: !!output?.isFinished,
              amount: outputTotal / paymentTerm.installments.length,
            } as SelectedInstallment)
        )
      );
    }
  };

  const handleSelectDueDate = (date: Date | null) => {
    setSelectedInstallment({
      ...selectedInstallment,
      dueDate: date,
    } as SelectedInstallment);
  };

  return (
    <>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Box component="form">
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <FormControl fullWidth error={!!errors?.operationId}>
                <Controller
                  name="operationId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={operations}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isOutputFinished}
                      value={
                        value
                          ? operations?.find(
                              (operation) => operation.id === value
                            )
                          : null
                      }
                      onChange={(event: any, newValue) => {
                        handleSelectOperation(newValue);
                        return onChange(newValue ? newValue.id : '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Tipo de Operação"
                          error={!!errors.operationId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.operationId && (
                  <FormHelperText>{errors.operationId.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <Controller
                control={control}
                name="issueDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label="Data de Emissão"
                    maxDate={new Date()}
                    readOnly={isOutputFinished}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur,
                        name,
                        error: !!fieldState.error,
                        helperText: errors?.issueDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="outputDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label="Data de Saída"
                    minDate={issueDate}
                    readOnly={isOutputFinished}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur,
                        name,
                        error: !!fieldState.error,
                        helperText: errors?.outputDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <FormControl fullWidth error={!!errors?.customerId}>
                <Controller
                  name="customerId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={customers}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isOutputFinished}
                      value={
                        value
                          ? customers?.find((customer) => customer.id === value)
                          : null
                      }
                      onChange={(event: any, newValue) => {
                        handleSelectCustomerPriceTable(
                          newValue ? newValue.priceTableId : null
                        );
                        setValue('note', newValue?.outputNote || '');
                        return onChange(newValue ? newValue.id : '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Cliente"
                          error={!!errors.customerId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.customerId && (
                  <FormHelperText>{errors.customerId.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={!!errors?.staffId}>
                <Controller
                  name="staffId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={staffs}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isOutputFinished}
                      value={
                        value
                          ? staffs?.find((staff) => staff.id === value)
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        onChange(newValue ? newValue.id : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Colaborador"
                          error={!!errors.staffId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.staffId && (
                  <FormHelperText>{errors.staffId.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <FormControl fullWidth error={!!errors?.priceTableId}>
                <Controller
                  name="priceTableId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={priceTables}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={isOutputFinished}
                      value={
                        value
                          ? priceTables?.find(
                              (priceTable) => priceTable.id === value
                            )
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        onChange(newValue ? newValue.id : '')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Tabela de Preço"
                          error={!!errors.priceTableId}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.priceTableId && (
                  <FormHelperText>{errors.priceTableId.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth error={!!errors?.installments}>
                <Controller
                  name="paymentTermId"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      options={paymentTerms}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      readOnly={
                        (currentOperation &&
                          !currentOperation?.generatesInstallment) ||
                        isOutputFinished
                      }
                      value={
                        value
                          ? paymentTerms?.find(
                              (paymentTerm) => paymentTerm.id === value
                            )
                          : null
                      }
                      onChange={(event: any, newValue) => {
                        handleSelectPaymentTerm(newValue);
                        return onChange(newValue ? newValue.id : null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...field}
                          {...params}
                          label="Condição de Pagamento"
                          error={!!errors.installments}
                        />
                      )}
                    />
                  )}
                />
                {!!errors?.installments && (
                  <FormHelperText>
                    Informe a condição de pagamento
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <TextField
              label="Observações"
              multiline
              disabled={isOutputFinished}
              maxRows={4}
              InputLabelProps={{ shrink: !!note }}
              {...register('note')}
            />
            <Stack direction="row" spacing={1} alignItems="center" mb={2}>
              <Typography variant="body1" fontWeight="bold">
                Produtos
              </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth error={!!errors?.products}>
                <Autocomplete
                  options={products}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Nenhum registro encontrado"
                  autoHighlight
                  readOnly={isOutputFinished}
                  value={
                    selectedProduct
                      ? products?.find(
                          (product) => product.id === selectedProduct.productId
                        )
                      : null
                  }
                  onChange={(event: any, newValue) =>
                    handleSelectProduct(newValue ? newValue.id : '')
                  }
                  disabled={isOutputFinished || isEditingProduct || !customerId}
                  sx={{
                    '.MuiInputBase-root': {
                      fontSize: 14,
                      height: '3.5rem',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Produto"
                      error={!!errors.products}
                    />
                  )}
                />
                {!!errors?.products && (
                  <FormHelperText>{errors.products.message}</FormHelperText>
                )}
              </FormControl>
              <TextField
                type="number"
                label="Quantidade"
                sx={{ width: { xs: '100%', sm: '20rem' } }}
                value={
                  selectedProduct?.productQuantity
                    ? Number(selectedProduct?.productQuantity)
                    : 0
                }
                onChange={({ target }) =>
                  handleChangeSelectedProductQuantity(target.value)
                }
                inputProps={{
                  style: {
                    fontSize: 14,
                    height: '1.438rem',
                  },
                }}
                disabled={!selectedProduct?.productId || isLoading}
              />
              <MoneyInput
                label="Preço"
                fullWidth
                sx={{ width: { xs: '100%', sm: '20rem' } }}
                value={
                  selectedProduct?.priceGross
                    ? Number(selectedProduct?.priceGross)
                    : 0
                }
                onChange={({ target }) =>
                  handleChangeSelectedProductPrice(target.value)
                }
                onBlur={({ target }) =>
                  handleBlurSelectedProductPrice(target.value)
                }
                inputProps={{
                  style: {
                    fontSize: 14,
                    height: '1.438rem',
                  },
                }}
                error={selectedProduct && !selectedProduct?.priceGross}
                disabled={!selectedProduct?.productId || isLoading}
              />
              <MoneyInput
                label="Desconto"
                sx={{ width: { xs: '100%', sm: '20rem' } }}
                value={
                  selectedProduct?.discount
                    ? Number(selectedProduct?.discount)
                    : 0
                }
                onChange={({ target }) =>
                  handleChangeSelectedProductDiscount(target.value)
                }
                inputProps={{
                  style: {
                    fontSize: 14,
                    height: '1.438rem',
                  },
                }}
                disabled={!selectedProduct?.productId || isLoading}
              />
              <MoneyInput
                label="Subtotal"
                fullWidth
                sx={{ width: { xs: '100%', sm: '20rem' } }}
                value={subtotalSelectedProduct}
                onChange={() => {}}
                inputProps={{
                  style: {
                    fontSize: 14,
                    height: '1.438rem',
                  },
                }}
                readOnly
              />
              <Tooltip title="Adicionar">
                <Box display="flex" alignItems="center">
                  <IconButton
                    color="primary"
                    size="large"
                    onClick={handleAddSelectedProduct}
                    disabled={
                      !selectedProduct ||
                      selectedProduct?.discount < 0 ||
                      selectedProduct?.priceGross <= 0
                    }
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
            <Box
              border={`1px solid ${theme.palette.grey[400]}`}
              borderRadius={1}
              bgcolor={theme.palette.grey[200]}
            >
              {currentProducts?.length ? (
                <OutputProductsList
                  products={currentProducts}
                  actions={{
                    handleEditProduct,
                    handleRemoveSelectedProduct,
                    isOutputFinished,
                  }}
                />
              ) : (
                <EmptyData sx={{ height: '10rem' }} />
              )}
            </Box>
            {!!currentOperation && currentOperation?.generatesInstallment && (
              <>
                <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Parcelas
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth error={!!errors.installments}>
                    <Autocomplete
                      options={documentTypes}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhum registro encontrado"
                      autoHighlight
                      disabled={
                        !isEditingInstallment &&
                        totalInstallments >= outputTotal
                      }
                      value={
                        selectedInstallment
                          ? documentTypes?.find(
                              (documentType) =>
                                documentType.id ===
                                selectedInstallment.documentTypeId
                            )
                          : null
                      }
                      onChange={(event: any, newValue) =>
                        handleSelectDocumentType(newValue as TDocumentType)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo de Documento"
                          error={!!errors.installments}
                        />
                      )}
                    />
                    {!!errors.installments && (
                      <FormHelperText>
                        {errors.installments.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <DatePicker
                    label="Data de Vencimento"
                    minDate={new Date()}
                    readOnly={isOutputFinished}
                    value={
                      selectedInstallment ? selectedInstallment?.dueDate : null
                    }
                    onChange={handleSelectDueDate}
                    disabled={
                      !isEditingInstallment && totalInstallments >= outputTotal
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                  />
                  <MoneyInput
                    label="Valor"
                    fullWidth
                    sx={{ width: { xs: '100%', sm: '20rem' } }}
                    value={
                      selectedInstallment?.amount
                        ? Number(selectedInstallment?.amount)
                        : 0
                    }
                    onChange={({ target }) =>
                      handleChangeSelectedInstallmentAmount(target.value)
                    }
                    inputProps={{
                      style: {
                        fontSize: 14,
                        height: '1.438rem',
                      },
                    }}
                    disabled={!selectedInstallment?.documentTypeId || isLoading}
                  />
                  <Tooltip title="Adicionar">
                    <Box display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={handleAddSelectedInstallment}
                        disabled={
                          !selectedInstallment ||
                          selectedInstallment?.amount < 0
                        }
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Stack>
                <Box
                  border={`1px solid ${theme.palette.grey[400]}`}
                  borderRadius={1}
                  bgcolor={theme.palette.grey[200]}
                >
                  {currentInstallments?.length ? (
                    <OutputInstallmentsList
                      installments={currentInstallments}
                      actions={{
                        handleEditInstallment,
                        handleRemoveSelectedInstallment,
                        isOutputFinished,
                      }}
                    />
                  ) : (
                    <EmptyData sx={{ height: '10rem' }} />
                  )}
                </Box>
              </>
            )}
            <OutputTotal products={currentProducts} />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          {isOutputFinished ? 'Fechar' : 'Cancelar'}
        </Button>
        <Button
          type="submit"
          disabled={isLoading || isOutputFinished}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </DialogActions>
    </>
  );
}
