import React, { ReactNode } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';

import { ColumnDefinitionType } from '../table.utils';

interface ITableRowsProps<T> {
  data: Array<T>;
  columns: ColumnDefinitionType<T>[];
  additionalData?: object;
  selectedKey?: keyof T;
  selected?: string[];
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (event: React.MouseEvent<unknown>, id: string) => void;
}

function getValue<T>(
  obj: T,
  column: ColumnDefinitionType<T>,
  additionalData: object = {}
) {
  if (typeof column.render === 'function') {
    return column.render({ ...obj, ...additionalData });
  }

  return obj[column.key] as ReactNode;
}

export function TableRows<T>({
  data,
  columns,
  additionalData,
  selectedKey,
  selected,
  onItemClick,
}: ITableRowsProps<T>) {
  const isSelected = (id: string) =>
    selected?.indexOf(id) !== undefined && selected?.indexOf(id) !== -1;

  const rows = data.map((row, index) => {
    const isItemSelected = isSelected(row[selectedKey as keyof T] as string);
    const labelId = `table-checkbox-${index}`;

    return (
      <TableRow
        key={`row-${index}`}
        sx={{
          whiteSpace: 'nowrap',
          cursor: selected ? 'pointer' : 'default',
        }}
        hover={!!selected}
        selected={isItemSelected}
        role={selected ? 'checkbox' : undefined}
        aria-checked={isItemSelected}
        tabIndex={-1}
        onClick={(event) =>
          onItemClick && selectedKey
            ? onItemClick(event, row[selectedKey] as string)
            : undefined
        }
      >
        {selected ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        ) : null}
        {columns.map((column, index2) => {
          return (
            <TableCell
              key={`cell-${index2}`}
              sx={{
                textAlign: column.align || 'left',
              }}
            >
              {getValue(row, column, additionalData)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
}
