import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { Product } from '@/@types/product';
import { ProductsTable } from '../ProductsTable';
import { ProductsTableColumns } from '../ProductsTable/ProductsTableColumns';

type TProductsData = {
  products: Product[];
  totalCount: number;
};

interface IProductsListProps {
  isLoading: boolean;
  isError: boolean;
  data: TProductsData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof Product;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function ProductsList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IProductsListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder
          headers={[
            'Nome',
            'Quantidade',
            'Unidade',
            'Categoria',
            'Criado em',
            'Ações',
          ]}
        />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados do produtos." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <ProductsTable
        columns={ProductsTableColumns}
        data={data?.products || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
