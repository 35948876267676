import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import * as zod from 'zod';
import { User } from '@/@types/user';
import { Role } from '@/@types/roles';
import { Staff } from '@/@types/staff';

const userFormSchema = zod
  .object({
    id: zod.string().optional(),
    name: zod
      .string()
      .min(2, 'Informe o nome do usuário')
      .transform((name) => name.trim().toUpperCase()),
    email: zod
      .string()
      .email('E-mail inválido')
      .transform((email) => email.trim().toLowerCase()),
    roleId: zod.string().min(1, 'Informe o perfil do usuário'),
    staffId: zod.string().min(1, 'Informe o colaborador do usuário'),
    password: zod
      .string()
      .min(6, 'Informe a senha: Mínimo de 6 caracteres')
      .optional()
      .or(zod.literal('')),
    passwordConfirmation: zod
      .string()
      .min(6, 'Confirme a senha')
      .optional()
      .or(zod.literal('')),
  })
  .refine(
    ({ id, password, passwordConfirmation }) =>
      id
        ? password === passwordConfirmation
        : password && password?.length >= 6,
    {
      message: 'As senhas não conferem',
      path: ['password'],
    }
  );

export type UserFormData = zod.infer<typeof userFormSchema>;

interface IUserFormProps {
  user?: User;
  roles: Role[];
  staffs: Staff[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: UserFormData) => void;
  onCancel: () => void;
}

export function UserForm({
  user,
  roles,
  staffs,
  isLoading,
  onSubmit,
  onCancel,
}: IUserFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      id: user?.id || '',
      name: user?.name || '',
      email: user?.email || '',
      roleId: user?.roleId || '',
      staffId: user?.staffId || '',
      password: '',
      passwordConfirmation: '',
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <FormControl fullWidth error={!!errors.roleId}>
              <Controller
                name="roleId"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={roles}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    value={
                      value ? roles?.find((role) => role.id === value) : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Perfil"
                        error={!!errors.roleId}
                      />
                    )}
                  />
                )}
              />
              {!!errors.roleId && (
                <FormHelperText>{errors.roleId.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={!!errors.staffId}>
              <Controller
                name="staffId"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Autocomplete
                    options={staffs}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Nenhum registro encontrado"
                    autoHighlight
                    value={
                      value ? staffs?.find((staff) => staff.id === value) : null
                    }
                    onChange={(event: any, newValue) =>
                      onChange(newValue ? newValue.id : '')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Colaborador"
                        error={!!errors.staffId}
                      />
                    )}
                  />
                )}
              />
              {!!errors.staffId && (
                <FormHelperText>{errors.staffId.message}</FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              type="text"
              label="Nome"
              autoFocus
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              disabled={isLoading}
              {...register('name')}
            />
            <TextField
              type="email"
              label="E-mail"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              disabled={isLoading}
              {...register('email')}
            />
          </Stack>
          <TextField
            type="password"
            label="Senha"
            error={!!errors.password}
            helperText={errors.password?.message}
            disabled={isLoading}
            {...register('password')}
          />
          <TextField
            type="password"
            label="Confirmação da senha"
            error={!!errors.passwordConfirmation}
            helperText={errors.passwordConfirmation?.message}
            disabled={isLoading}
            {...register('passwordConfirmation')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
