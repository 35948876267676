import { Box } from '@mui/material';
import {
  ResponseDataHandle,
  ListPlaceholder,
  ErrorList,
  EmptyData,
} from '@/components/common';
import { ITablePaginationProps } from '@/components/common/Table/TablePagination';
import { Role } from '@/@types/roles';
import { RolesTable } from '../RolesTable';
import { RolesTableColumns } from '../RolesTable/RolesTableColumns';

type TRolesData = {
  roles: Role[];
  totalCount: number;
};

interface IRolesListProps {
  isLoading: boolean;
  isError: boolean;
  data: TRolesData | undefined;
  sort?: 'desc' | 'asc';
  sortBy?: keyof Role;
  // eslint-disable-next-line no-unused-vars
  onSort?: (header: string) => void;
  actions: object;
  pagination?: ITablePaginationProps;
}

export function RolesList({
  isLoading,
  isError,
  data,
  sort,
  sortBy,
  onSort,
  actions,
  pagination,
}: IRolesListProps) {
  return (
    <ResponseDataHandle
      isLoading={isLoading}
      loadingComponent={
        <ListPlaceholder headers={['Nome', 'Criado em', 'Ações']} />
      }
      isError={isError}
      errorComponent={
        <Box py={10}>
          <ErrorList text="Falha ao obter os dados dos colaboradores." />
        </Box>
      }
      totalItems={data?.totalCount}
      emptyComponent={
        <Box py={10}>
          <EmptyData />
        </Box>
      }
    >
      <RolesTable
        columns={RolesTableColumns}
        data={data?.roles || []}
        sort={sort}
        sortBy={sortBy}
        onSort={onSort}
        actions={actions}
        pagination={pagination}
      />
    </ResponseDataHandle>
  );
}
