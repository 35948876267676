import { Permission, PermissionsGrouped } from '@/@types/permission';
import { Role } from '@/@types/roles';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { RoleForm, RoleFormData } from '../RoleForm';
import { RoleFormPlaceholder } from '../RoleForm/RoleFormPlaceholder';

interface IRoleModalProps {
  role?: Role;
  permissions: PermissionsGrouped[];
  rawPermissions: Permission[];
  isOpen: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: RoleFormData) => void;
}

export function RoleModal({
  role,
  permissions,
  rawPermissions,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isSubmitting,
}: IRoleModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {role ? 'Alterar Perfil de Usuário' : 'Novo Perfil de Usuário'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting ? <LinearProgress /> : null}
      {isLoading ? (
        <RoleFormPlaceholder />
      ) : (
        <RoleForm
          role={role}
          rawPermissions={rawPermissions}
          permissions={permissions}
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
