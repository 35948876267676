/* eslint-disable no-nested-ternary */
import { Situation } from '@/@types/situation';
import { useSettings } from '@/modules/settings/hooks';
import { capitalizeWord } from '@/utils/capitalizeWord';
import { Stack, Typography } from '@mui/material';
import { grey, blue, purple, orange } from '@mui/material/colors';
import { useMemo } from 'react';

interface IOutputStatusLabelBulletProps {
  isFinished: boolean;
  situation?: Situation | null;
  deletedAt?: string;
}

export function OutputStatusLabelBullet({
  isFinished,
  situation,
  deletedAt,
}: IOutputStatusLabelBulletProps) {
  const settings = useSettings({});

  const transitSituationId = settings.data?.settings?.salesTransitSituationId;
  const isTransitSituation = situation?.id === transitSituationId;

  const color = useMemo(() => {
    return deletedAt
      ? grey[700]
      : isFinished
      ? blue[700]
      : isTransitSituation
      ? purple[700]
      : orange[700];
  }, [deletedAt, isFinished, isTransitSituation]);

  const label = useMemo(() => {
    return deletedAt
      ? 'Excluído'
      : isFinished
      ? 'Finalizado'
      : situation
      ? capitalizeWord(situation.name)
      : 'Pendente';
  }, [deletedAt, isFinished, situation]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        '&::before': {
          content: '""',
          display: 'inline-block',
          width: '0.5rem',
          height: '0.5rem',
          borderRadius: '50%',
          marginRight: '0.5rem',
          backgroundColor: color,
        },
      }}
    >
      <Typography color={color}>{label}</Typography>
    </Stack>
  );
}
