import {
  Box,
  DialogContent,
  Stack,
  DialogActions,
  Skeleton,
} from '@mui/material';

export function PaymentTermFormPlaceholder() {
  return (
    <Box>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Skeleton height={72} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={3}
        >
          <Skeleton width="100%" height={72} />
          <Skeleton width="20%" height={72} />
          <Skeleton
            variant="circular"
            width="2.5rem"
            height="2.5rem"
            sx={{ flexShrink: 0 }}
          />
        </Stack>
        <Stack>
          <Skeleton height={160} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Skeleton width={100} height={56} />
        <Skeleton width={100} height={56} />
      </DialogActions>
    </Box>
  );
}
