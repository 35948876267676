import { StyledTableCell } from '@/styles/staffs.styles';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

interface IListPlaceholderProps {
  headers: string[];
}

export function ListPlaceholder({ headers }: IListPlaceholderProps) {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {headers?.map((header) => (
            <StyledTableCell key={header} sx={{ fontWeight: 'bold' }}>
              {header}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(8).keys()]?.map((key) => (
          <TableRow key={key}>
            {headers?.map((header) => (
              <TableCell key={header}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
