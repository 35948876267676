import { TOutputInstallment } from '@/@types/outputs';
import { Table } from '@/components/common';
import { OutputInstallmentsTableColumns } from './OutputInstallmentsTableColumns';

interface IOutputInstallmentsListProps {
  installments: TOutputInstallment[];
  actions: object;
}

export function OutputInstallmentsList({
  installments,
  actions,
}: IOutputInstallmentsListProps) {
  return (
    <Table
      data={installments}
      columns={OutputInstallmentsTableColumns}
      additionalData={actions}
      maxHeight={255}
    />
  );
}
