import React from 'react';
import { theme } from '@/styles/theme';
import { Edit, Delete, RestoreFromTrash } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { Can } from '@/components/common';

interface ICategoriesTableActionsProps {
  id: string;
  deletedAt?: string;
  // eslint-disable-next-line no-unused-vars
  onEditCategory: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteCategory: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onRestoreCategory: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEditCategoryMouseEnter: (id: string) => void;
}

export function CategoriesTableActions({
  id,
  deletedAt,
  onEditCategory,
  onDeleteCategory,
  onRestoreCategory,
  onEditCategoryMouseEnter,
}: ICategoriesTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Can permissions={['categories_update']}>
        <Tooltip title="Alterar">
          <IconButton
            onMouseEnter={() => onEditCategoryMouseEnter(id)}
            onClick={() => onEditCategory(id)}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Can>
      {deletedAt ? (
        <Can permissions={['categories_restore']}>
          <Tooltip title="Restaurar">
            <IconButton
              onClick={() => onRestoreCategory(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <RestoreFromTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      ) : (
        <Can permissions={['categories_delete']}>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => onDeleteCategory(id)}
              size="small"
              sx={{
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Can>
      )}
    </Stack>
  );
}
