import {
  Box,
  DialogContent,
  Stack,
  DialogActions,
  Skeleton,
} from '@mui/material';

export function UnitFormPlaceholder() {
  return (
    <Box>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack>
          <Skeleton height={72} />
          <Skeleton height={72} />
          <Skeleton height={72} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Skeleton width={100} height={56} />
        <Skeleton width={100} height={56} />
      </DialogActions>
    </Box>
  );
}
