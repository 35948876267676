import { Customer } from '@/@types/customer';
import { PriceTable } from '@/@types/price-table';
import { Modal } from '@/components/common';
import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { CustomerForm, CustomerFormData } from '../CustomerForm';
import { CustomerFormPlaceholder } from '../CustomerForm/CustomerFormPlaceholder';

interface ICustomerModalProps {
  customer?: Customer;
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSubmitting: boolean;
  priceTables: PriceTable[];
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: CustomerFormData) => void;
}

export function CustomerModal({
  customer,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isFetching,
  priceTables,
  isSubmitting,
}: ICustomerModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      closeOnBackdropClick={false}
    >
      <DialogTitle id="scroll-dialog-title" sx={{ p: 2 }}>
        {customer ? 'Alterar Cliente' : 'Novo Cliente'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {isSubmitting || isFetching ? (
        <Box>
          <LinearProgress />
        </Box>
      ) : null}
      {isLoading ? (
        <CustomerFormPlaceholder />
      ) : (
        <CustomerForm
          customer={customer}
          isLoading={isSubmitting}
          priceTables={priceTables}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Modal>
  );
}
