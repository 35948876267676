import { TPaymentTermInstallment } from '@/@types/payment-term';
import { Table } from '@/components/common';
import { PaymentTermInstallmentsTableColumns } from './PaymentTermInstallmentsTableColumns';

interface IPaymentTermInstallmentsListProps {
  installments: TPaymentTermInstallment[];
  actions: object;
}

export function PaymentTermInstallmentsList({
  installments,
  actions,
}: IPaymentTermInstallmentsListProps) {
  return (
    <Table
      data={installments}
      columns={PaymentTermInstallmentsTableColumns}
      additionalData={actions}
      maxHeight={255}
    />
  );
}
